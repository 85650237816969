.custom_table_design table{
    border: 1px solid #EAECF0 !important;
    /* height: 100vh; */
    overflow-y: scroll;
}

.custom_table_design .ant-table-thead{
    background: #EFF8FF !important;
}

.custom_table_design .ant-table-thead > tr > th, .custom_table_design .ant-table-thead > tr > td{
    border-bottom: 1px solid #EAECF0 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.custom_table_design .ant-table-tbody > tr > td{
    border-color:#EAECF0 !important;
    font-size: 14px !important;
}

.custom_table_design .ant-table-tbody > tr:nth-child(even) > td{
    background: #FAFBFD !important;
}
.ant-spin-dot-item{
background-color: var(--secondary-color) !important;
}
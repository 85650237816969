@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

.notificationContainer {
  height: 98vh;
  margin: 1vh 10px;
  padding: 32px 30px;
  border-radius: 10px;
  background-color: #fff;
}

.maindivnotification {
  width: 100%;
  height: 100vh;
  /* background-color: white; */
  /* border: 1px solid black; */
  padding: 32px;
}

.headingandbtndiv {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  padding: 6px;
}

.notificationHeading {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.notificationbtn {
  color: white;
  /* background-color: black; */
  background-color: var(--secondary-color) !important;
  border-radius: 8px;
  width: auto;
  height: 40px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 12px;
  line-height: 20px;
}

.anticon svg {
  /* color: #ffffff !important; */
  font-size: 16px;
}

.mark-read {
  position: relative;
  top: 3px;
  font-size: 16px;
}

.notificationbtn:hover {
  color: var(--secondary-color);
  /* color: #BDE556 !important; */
  transition: 0.5s;
}

.mark-read:hover {
  /* color: #BDE556 !important; */
  color: var(--secondary-color);
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--secondary-color);
}
.ant-tabs .ant-tabs-ink-bar {
  background-color: var(--secondary-color);
}
.ant-tabs .ant-tabs-tab:hover {
  color: var(--secondary-color);
}
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: var(--secondary-color);
}
/* Media query */

/* for 600px */

@media screen and (max-width: 636px) {
  .headingandbtndiv {
    display: block;
    text-align: center;
  }

  .notificationbtn {
    display: block;
    margin: 5px auto;
  }

  .notificationbtn {
    width: 180px;
  }

  .mark-read {
    position: absolute;
    left: 15px;
    top: 12px;
  }
}

.notificationRow {
  display: flex;
  gap: 15px;
  align-items: center;

  .noti-content {
    flex: 1;
  }
  .noti-delete-icon {
    svg {
      font-size: 18px;
      color: red;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 600px) {
  .notificationRow {
    flex-direction: column;
  }
}

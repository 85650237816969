._customTableWrapper {
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(234, 236, 240, 1);
  overflow-x: auto;
}
._customTable {
  /* border-color: rgba(234, 236, 240, 1); */
}

._customTable tr th,
._customTable tr td {
  line-height: normal;
}
._customTable tr th:first-child {
  border-top-left-radius: 8px;
  border-left: none;
}
._customTable tr th:last-child {
  border-top-right-radius: 8px;
  border-right: none;
}
._customTable tr th {
  padding: 12px 12px 12px 12px;
  background-color: rgba(239, 248, 255, 1);
  font-size: 12px;
  font-weight: 500;
  color: rgba(38, 37, 42, 1);
}
._customTable tr td {
  padding: 16px 12px 16px 12px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(38, 37, 42, 1);
  min-width: 140px;
  padding: 16px 12px 16px 12px;
}
._customTable ._subjectNameTd {
  font-size: 14px;
  font-weight: 600;
  color: rgba(38, 37, 42, 1);
  background-color: rgba(250, 251, 253, 1);
}
._customTable tr ._gradeTd {
  font-size: 12px;
  font-weight: 500;
  color: rgba(38, 37, 42, 1);
  min-width: 80px !important;
  width: 80px !important;
  text-align: right;
  padding: 0 !important;
}
._customTable tr ._gradeTd > div {
  padding: 16px 12px 16px 12px;
}
._customTable tr ._gradeTd ._match {
  background-color: aliceblue;
}
._customTable tr ._gradeTd ._NotMatch {
  cursor: pointer;
}

/* BORDERS */
._customTable tr th {
  line-height: normal;
  /* border-top: 1px solid rgba(234, 236, 240, 1); */
}
._customTable tr th,
._customTable tr td {
  line-height: normal;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  border-right: 1px solid rgba(234, 236, 240, 1);
}
._customTable tr th {
  border-left: 1px solid rgba(234, 236, 240, 1);
}
._customTable tr:last-child td {
  /* border-left: 1px solid rgba(234, 236, 240, 1); */
  border-bottom: none;
}

._customTable tr td:last-child {
  /* border-left: 1px solid rgba(234, 236, 240, 1); */
  border-right: none;
}

.disable_text{
  cursor:not-allowed;
}



.form-add-more,
.form-done {
  display: flex;
  justify-content: flex-start;
  border: none;
  box-shadow: none;
}

.form-add-more:hover {

  border: none !important;
}

.form-add-more-icon {
  position: relative;
  top: 5px;
  right: 5px;
}


.custom-table .ant-table-cell {
  border: 1px solid #d9d9d9;
}

.custom-table .ant-table-tbody>tr>td {
  border: 1px solid #d9d9d9;
}

.custom-table .ant-table-thead>tr>th {
  border: 1px solid #d9d9d9;
}

.custom-table-parent {
  background-color: #FFFFFF;
  overflow: scroll;
  height: 60vh !important;
}

.next_btn {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  margin-top: 5px;
  width: 49%;
}




.points {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Plus Jakarta Sans";
}

/* .black-button {
  display: grid;
  place-items: center;
  border-radius: 8px;
  height: 30px !important;
  width: 30px !important;
} */

.step-two-scroll {
  padding: "10px";
  overflow-y: "scroll",
}

.points li {
  font-size: 16px;
}



.custom-table thead {
  background-color: #eff8ff;
}

.custom-table tr td {
  width: 200px;
}

.prepare-assessment {
  margin-bottom: 5px;
}

.ant-steps-item-icon {
  background-color: var(--secondary-color) !important;
  border-color: #175cd3 !important;
}

.anticon svg {
  color: #ffffff;
}

.ant-steps-item-title::before {
  background-color: grey !important;
}

.ant-steps-item-title::after {
  background-color: var(--secondary-color) !important;
}

.some {
  color: #667085 !important;
}
.ant-steps-item-process .ant-steps-item-icon{
  background-color:var(--secondary-color);
}

.done-btn {
  color: #667085;
  margin: 5px;
  width: 48%;
}


.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--primary-color); /* Border color when step is complete */
  background-color: var(--secondary-color); /* Background color when step is complete */
}

.ant-steps-item-wait .ant-steps-item-icon {
  color: var(--primary-color);
}

.step-two-scroll {
  background-color: #FFFFFF;
  border: none !important;
}

.ant-steps-item-icon {
  background-color: var(--secondary-color); 
  border-color: var(--secondary-color) !important;
}

.ant-steps-item-title::after {
  background-color: var(--secondary-color) !important;
}

.ant-steps-icon {
  font-weight: bold;
}

.form-parent {
  background-color: #FFFFFF;
  max-height: auto;
  padding: 20px;
  height: 100%;
}

.assessment-parent-div {
  padding: 20px;
  height: 100%;
}

.steps {
  background-color: #fff;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .next_btn {
    width: 100%;

  }

  .steps {
    padding: 0;
    margin-top: 20px;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@600&display=swap'); */

/* h1 {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 36px; 
    letter-spacing: -0.02em; 
    color: black;
} */

/* p {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

} */

/* Button{
    background:transparent;
    border: none;
} */

.login-header {
  height: 8vh;
  width: 50%;
}

.login-header .newLogoDiv {
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    width: 35px;
    height: auto;
  }
  h3 {
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0px;
    color: #5b5a5c;
  }
}

.login-header .logo {
  width: 100% !important;
  height: 8vh;
  display: flex;
  background: #f7f7f7;
  /* justify-content: center; */
  align-items: center;
  padding-left: 40px;
}

.login-header .logo .logo-div {
  width: 90%;
}

.tank {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f7f7f7;
  height: 92vh;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f7f7f7;
}

.credentials-page .auth-form {
  width: 60%;
}

.credentials-page .auth-form h1 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 10px;
  letter-spacing: -0.02em;
  /* Letter spacing: -2% */
  color: black;
}

.credentials-page .auth-form p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.credentials-page .back-to-login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.credentials-page .submit-btn {
  border-radius: 8px;
  height: 44px;
  width: 100% !important;
  background-color: var(--secondary-color) !important;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 6px;
}

.credentials-page .image-div {
  height: 92vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.remember-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.credentials-page .caution {
  text-align: center;
}

.credentials-page .caution span {
  font-weight: 700;
  cursor: pointer;
}

@media (max-width: 1040px) {
  .credentials-page .auth-form {
    width: 80%;
  }
}
@media (max-width: 991px) {
  .credentials-page .image-div {
    display: none;
  }
  .login-header {
    height: 8vh;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .credentials-page .auth-form {
    width: 87%;
  }

  .tank {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #f7f7f7;
  }

  .login-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #f7f7f7;
    height: 85vh;
  }

  .login-header {
    height: 15vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0;
    width: 100%;
    background: #f7f7f7;
  }

  .login-header .logo .logo-div {
    width: 60%;
  }

  .login-header .logo {
    /* background-color: #fff; */
    width: initial;
  }
}

@media (max-width: 550px) {
  .credentials-page .auth-form {
    width: 80%;
  }
}

@media screen and (max-width: 576px) {
  .login-header .newLogoDiv {
    display: none !important;
  }
}

._progressNoteModal {
  width: 516px !important;
}
._progressNoteModal ._modalBody {
}
._progressNoteModal ._modalBody ._modalHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
._progressNoteModal ._modalBody ._modalHeader ._imageBox img {
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 50%;
}
._progressNoteModal ._modalBody ._modalHeader ._content {
}
._progressNoteModal ._modalBody ._modalHeader ._content > h2 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -2%;
}
._progressNoteModal ._modalBody ._modalHeader ._content > div {
  display: flex;
  align-items: center;
  gap: 5px;
}
._progressNoteModal ._modalBody ._modalHeader ._content > div p {
  font-size: 14px;
  font-weight: 400;
}
._progressNoteModal ._modalBody ._modalHeader ._content > div svg {
  color: rgba(189, 229, 86, 1);
}

._progressNoteModal ._modalBody ._lessonBox {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: rgba(239, 248, 255, 1);
  margin-top: 30px;
}
._progressNoteModal ._modalBody ._lessonBox p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(23, 92, 211, 1);
}

._progressNoteModal ._modalBody ._textareaBox {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
._progressNoteModal ._modalBody ._textareaBox textarea {
  border: 1px solid rgba(234, 236, 240, 1);
  border-radius: 8px;
}

._progressNoteModal ._modalBody ._addButton {
  border: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
}
._progressNoteModal ._modalBody ._addButton:hover {
  border: none !important;
  background-color: transparent !important;
}

._progressNoteModal ._modalBody ._descriptionBox {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 130px;
  overflow-y: auto;
}

._progressNoteModal ._modalBody ._descriptionBox ._descriptionCard {
  display: flex;
  flex-direction: column;
}
._progressNoteModal ._modalBody ._descriptionBox ._descriptionCard > p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
}
._progressNoteModal ._modalBody ._descriptionBox ._descriptionCard > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
._progressNoteModal ._modalBody ._descriptionBox ._descriptionCard > div > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
._progressNoteModal ._modalBody ._descriptionBox ._descriptionCard > div h2 {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._progressNoteModal ._modalBody ._descriptionBox ._descriptionCard > div p {
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  padding: 3px 6px;
  color: black;
  border: 1px solid rgba(234, 236, 240, 1);
  width: max-content;
}

._progressNoteModal ._modalBody ._actionButton {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
}
._progressNoteModal ._modalBody ._actionButton button {
  flex: 1;
  border-radius: 8px;
  /* font-size: 16px; */
  font-weight: 600;
}
._progressNoteModal ._modalBody ._actionButton button:last-child {
  background-color: var(--secondary-color);
  color: #fff;
}

._progressNoteModal ._modalBody ._historyLoadingBox,
._progressNoteModal ._modalBody ._historyEmptyBox {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}
._progressNoteModal ._modalBody ._historyEmptyBox p {
  font-size: 14px;
}

.file-label {
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
}
.file-input {
  display: none;
}

._progressNoteModal ._modalBody ._fileNameRow {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
._progressNoteModal ._modalBody ._fileNameRow p {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.select_logo{
    width: 160px;
}
.buttonss{
    display: none;
}


@media screen and (max-width: 502px) {
    .select_logo{
        width: 100% !important;
    }
    .buttonss{
        display: block;
    }
    .buttonss Button{
        font-size: 10px;

    }

}


._weekCalendarContainer {
  width: 100%;
}

._weekCalendarContainer ._weekCalendarHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
._weekCalendarContainer ._weekCalendarHeader > p {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

._weekCalendarContainer ._dateBox {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
._weekCalendarContainer ._dateBox ._dateCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
._weekCalendarContainer ._dateBox ._dateCard > p {
  margin-bottom: 20px;
}

._weekCalendarContainer ._dateBox ._dateCard p {
  font-size: 12px;
  font-weight: 500;
  color: black;
}
._weekCalendarContainer ._dateBox ._activeDate {
  background-color: var(--secondary-color);
  border-radius: 30px;
  padding: 8px 5px 5px 5px;
  margin-top: -8px;
}
._weekCalendarContainer ._dateBox ._activeDate > p {
  margin-bottom: 12px;
}
._weekCalendarContainer ._dateBox ._activeDate > div {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
}
._weekCalendarContainer ._dateBox ._activeDate p {
  color: #fff;
}

.student-table {
   height: 46.7vh;
}

.tooltip {
   width: 30px;
   padding: 5px;
   margin-left: 5px;
   display: flex;
   justify-content: center;
   color: white;
   background-color: var(--secondary-color);
}
.not_allowed{
   cursor: not-allowed;
}

.hide_action{
   display: none;
}
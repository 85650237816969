._curriculumDetailsContainer
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 6px 16px;
}

._curriculumDetailsContainer {
  position: relative;
}
._curriculumDetailsContainer ._loadingOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 999;

  background-color: rgba(205, 205, 205, 0.1);
}

._innerCollapse .ant-collapse-item > .ant-collapse-header {
  background-color: rgba(239, 248, 255, 1) !important;
  /* background-color: black !important; */
  border-radius: 8px;
}

._levelCollapse {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

._levelCollapse .ant-collapse-item > .ant-collapse-header {
  background-color: rgba(250, 251, 253, 1) !important;
  border-radius: 8px;
}
._levelCollapse
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  background-color: rgba(250, 251, 253, 1) !important;
}
._levelCollapse
  .ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  background-color: rgba(250, 251, 253, 1) !important;
  padding-top: 20px;
  padding-bottom: 0px;
}

.curriculumDetailLevel {
  margin-top: 20px;
}

.panel_hdr_content {
  display: flex;
  align-items: center;
  gap: 7px;
}

.collapse_action_btn {
  border-radius: 8px;
  height: 40px;
  width: 40px !important;
}

.collapse_action_btn svg {
  margin-top: 2px;
}

.archive_unarchive_btn {
  width: 25px;
  height: 25px;
  margin: 10px 0px;
  cursor: pointer;
  color: black;
}

.lesson_archive_unarchive_btn {
  width: 20px;
  height: 20px;
  margin: 10px 0px;
  cursor: pointer;
  color: black;
}

.lesson_list_item {
  display: flex;
  justify-content: space-between;
}

.lesson_list_item > li {
  cursor: pointer;
  color: black;
}

.activeDrag {
  padding-inline: 10px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
}

.dndItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dndContent {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      background-color: var(--primary-color);
      width: 36px;
      height: 30px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

._curriculumDetailsContainer {
  width: 100%;
  height: calc(100% - 10px);
}

._curriculumDetailsContainer .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none !important;
  border-radius: 8px;
  /* margin-bottom: 10px; */
}

._curriculumDetailsContainer
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  align-items: center !important;
}

._curriculumDetailsContainer ._isLoading {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.maindivdetails {
  padding: 32px 32px 32px 32px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 20px;
  width: 100%;
  height: calc(100vh - 20px);
  overflow-y: auto;
}

.maindivdetails ._heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}
.maindivdetails ._heading h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

.moduledurationclock {
  width: max-content;
  display: flex;
  border-radius: 6px;
  height: 30px;
  padding-inline: 10px;
  background-color: #eff8ff;
  display: flex;
  gap: 5px;
  align-items: center;
}
.moduledurationclock img {
  height: 18px;
  width: 18px;
}
.moduledurationclock p {
  font-size: 14px;
  font-weight: 600;
  color: blue;
}

.collapsedata {
  /* border: 1px solid black; */
  top: 10px;
  width: 100%;
}
._innerCollapse {
  background-color: rgba(250, 251, 253, 1) !important;
}
.innerpanel {
  margin-bottom: 24px;
}

.levelbtndiv {
  display: flex;
  /* border: 1px solid black; */
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.levelbtndiv .black-button svg {
  margin-bottom: -5px;
}

.addlevel {
  background: black;
  color: white;
  padding: 10px;
  border: 1px solid;
  position: relative;
  border-radius: 13px;
}

.addlevel:hover {
  background-color: black;
}

.target {
  max-height: 80vh;
  overflow-y: scroll;
  border-radius: 10px;
}

.spin {
  color: white;
}

.points-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.level {
  font-weight: 700;
  font-family: "Plus+Jakarta+Sans";
  margin-top: 20px;
  outline: none;

  border: none;
}
.levelTitle {
  font-size: 20px;
  font-weight: 700;
  font-family: "Plus Jakarta Sans";
}

.level Input {
  outline: none;
  font-family: "Plus Jakarta Sans";
}
.btn-parent {
  display: flex;
  gap: 10px;
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(180deg);
}

.hovered {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.ant-color-picker
  .ant-color-picker-inner-content
  .ant-color-picker-input-container
  .ant-color-picker-format-select.ant-select-single
  .ant-select-arrow,
.ant-color-picker
  .ant-color-picker-inner-content
  .ant-color-picker-input-container
  .ant-color-picker-format-select.ant-select-single
  .ant-select-selector {
  display: none;
}

.ant-color-picker-trigger .ant-color-picker-color-block {
  width: 100%;
}

@media (max-width: 600px) {
  .maindivdetails {
    padding: 0;
    margin-top: 0px;
  }
  .target {
    width: 100%;
  }
}

.required_icon_input {
  color: red;
}

.color_picker {
  width: 50%;
}

.btn-parent button:first-child {
  background-color: white;
  color: black;
}

.btn-parent button:nth-of-type(2) {
  background-color: var(--secondary-color);
  color: white;
}

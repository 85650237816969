._progressCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
._progressCard ._iconBox {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: rgba(224, 242, 254, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

._progressCard ._detail {
  width: calc(100% - 170px);
}
._progressCard ._detail h2 {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._progressCard ._detail p {
  font-size: 14px;
  font-weight: 500;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._progressCard ._progressBar {
  width: 120px;
}

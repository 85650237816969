._curriculumContainer ._isLoading {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

._curriculumContainer {
  width: 100%;
  border-radius: 20px;
  padding: 32px 32px 32px 32px;
  background-color: #fff;
  margin-top: 10px;
  min-height: calc(100% - 20px);
}
._curriculumContainer .curriculum_heading{
  display: flex;
  justify-content: space-between;
}
._curriculumContainer .curriculum_heading h1{
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
  font-size: 30px;
  color: #26252a;
}
._curriculumContainer ._curriculumHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
._curriculumContainer ._curriculumHeader h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}
.curriculum_heading .curriculum_btn{
  display: flex;
}

._curriculumContainer ._subjectBox {
  width: 100%;
  display: flex;
  gap: 10px;
  overflow: auto;
  margin-top: 30px;
}
._curriculumContainer ._subjectBox ._subjectButton {
  padding: 7px 10px;
  border-radius: 6px;
  cursor: pointer;
  height: 40px;
}
._curriculumContainer ._subjectBox ._subjectButton p {
  width: max-content;
  color: rgba(102, 112, 133, 1);
  font-weight: 600;
  font-size: 14px;
}
._curriculumContainer ._subjectBox ._subjectButtonActive {
  padding:10px;
  border-radius: 6px;
  background-color:var(--secondary-color);
  cursor: pointer;
  height: 40px;
}
._curriculumContainer ._subjectBox ._subjectButtonActive p {
  width: max-content;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
}

._curriculumContainer ._curriculumTable {
  margin-top: 30px;
}
._curriculumContainer
  .ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container {
  border-top: 1px solid rgba(234, 236, 240, 1) !important;
  border-inline-start: 1px solid rgba(234, 236, 240, 1) !important;
}
._curriculumContainer .ant-table-wrapper .ant-table-container {
  border-radius: 8px !important;
}

._curriculumTable .ant-table-wrapper .ant-table-thead > tr > th {
  padding: 8px 16px;
  text-align: center;
} 
._curriculumTable .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 40px 16px;
  width: auto;
  text-align: center;
}
._curriculumTable .ant-table-wrapper .ant-table-tbody > tr > td:first-child {
  background-color: rgba(250, 251, 253, 1) !important;
  border-bottom-left-radius: 8px;
}
._curriculumTable .ant-table-wrapper .ant-table-tbody > tr > td:last-child {
  border-bottom-right-radius: 8px;
}

._curriculumTable .ant-table-wrapper .ant-table-thead > tr > th,
._curriculumTable .ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(234, 236, 240, 1) !important;
  border-inline-end: 1px solid rgba(234, 236, 240, 1) !important;
}

._curriculumTable ._emptyCurriculum {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

._curriculumTable ._emptyCurriculum ._addCurriculumButton {
  width: 300px;
  height: 100px;
  border-radius: 10px;
  background-color: rgba(250, 251, 253, 1);
  border: 1px dashed gray;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.divbtn {
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
}

.maqasidbtn {
  display: flex;
  width: 100%; /* Adjusted to allow flexible resizing */
  height: 40px;
  justify-content: center;
  align-items: center; /* Center the content vertically */
  border-radius: 8px;
  background: black;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px; /* Added padding for better appearance */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.subjects_data{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
}

.btndiv {
  display: flex;
  gap: 9px;
  justify-content: space-between; /* Space the buttons equally */
  width: 100%;
  padding: 10px; /* Optional: Add padding for spacing around the container */
  box-sizing: border-box; /* Ensure padding is included in width */
}
/* 

.custom-tabs .add-btn-icon {
    background: none;
    border: none;
    padding: 0;
    margin-left: 8px;
    font-size: 16px;
    cursor: pointer;
    vertical-align: middle;
}

.custom-tabs .add-btn-icon:hover {
    color: #1890ff;
} */

@media (max-width: 600px) {
  ._curriculumContainer {
    padding: 0;
  }
}

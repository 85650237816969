.settingMin {
    padding: 10px;
    background-color: #ffff;
    /* min-height: 100vh; */
    border-radius: 10px;
    color: rgba(38, 37, 42, 1);
    /* height: 100vh; */
    width: 100%;
    height:auto;
}

.reporting {
    overflow-x: auto;
    width: 100%;
    height: auto;
}

.settingHeading {
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: rgba(38, 37, 42, 1)
}

.settingTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(102, 112, 133, 1)
}

.profileAndButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.profileAndButtons h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
}

.yourprofile h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    color: rgba(38, 37, 42, 1);
}

.yourprofile p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.buttons {
    display: flex;
    gap: 15px;
}

.buttons Button:first-child {
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    /* padding: 10px 15px; */
    min-height: 45px;
    border-radius: 8px;
    border: 1px solid rgba(16, 24, 40, 0.21);
    color: rgba(38, 37, 42, 1)
}

.buttons Button:last-child {
    /* background-color: rgba(38, 37, 42, 1); */
    background: var(--secondary-color) !important;
    font-size: 14px;
    font-weight: 600;
    min-height: 45px;
    /* padding: 10px 15px; */
    border-radius: 8px;
    border: 1px solid rgba(16, 24, 40, 0.21);
    color: rgba(234, 236, 240, 1)
}

.buttons Button {
    border: 1px solid rgba(16, 24, 40, 0.21);
}

.buttonsmobile {
    display: none;
}

.Nameinputdata {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto;
}
.Nameinputdata .ant-form-item{
    width: 100%;
    margin-bottom: 0px;
}
.Nameinputdata>div:first-child {
    /* flex: 0 1 auto; */
    width: 25%;
}

.Nameinputdata .InputField {
    border: 1px solid rgba(16, 24, 40, 0.21);
    border-radius: 8px;
    padding: 10px auto;
}

.newpassword-fields {
    width: 100%;
}

.newpassword_InputField {
    width: 100%;
    border: 1px solid rgba(16, 24, 40, 0.21);
    border-radius: 8px;
    padding: 10px auto;
}

.newpassword {
    width: 100%;
}

.Nameinputdata .InputField:hover {
    border: 1px solid rgba(16, 24, 40, 0.21);
}

.Nameinputdata h2 {
    font-weight: 600;
    font-size: 14px;
    Line-height: 20px;
}

.newpassword {
    width: 100%;
}

.newpassword .InputField {
    width: 100%;
    border: 1px solid rgba(16, 24, 40, 0.21);
    border-radius: 8px;
    width: 80%;
    /* border : 2px solid green */
}

.InputField span {
    /* position:absolute; */
    top: 30px
}

.newpassword .InputField :hover {
    border: 1px solid rgba(16, 24, 40, 0.21);
}

.newpassword span {
    font-weight: 400;
    font-size: 14px;
    Line-height: 20px
}

.Nameinputdata p {
    font-weight: 400;
    font-size: 14px;
    Line-height: 20px
}

.uploadimag {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    /* align-items: center; */
    margin: 20px auto;
}

.uploadimag h2 {
    font-weight: 600;
    font-size: 14px;
    Line-height: 20px
}

.uploadimag>div:first-child {
    width: 25%;
}

.Dragger {
    width: 100%;
}

.custom_dragger .ant-upload-drag {
    border: 2px dashed rgba(16, 24, 40, 0.21);
    width: 100%;
}

.custom_dragger .ant-upload-drag:hover {
    border: 2px dashed rgba(16, 24, 40, 0.21) !important;
}

.custom_dragger .uploadicon {
    padding: 10px;
    border: 1px solid rgba(16, 24, 40, 0.21);
    border-radius: 8px;
    font-size: 45px;
}

.flatformColor {
    display: flex;
    gap: 50px;
    /* border: 3px solid red; */
    align-items: center;
    margin: 30px 0px;
}

.colornameAndChecks {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 20%;
}

.colornameAndChecks h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.colorContainer {
    position: relative;
    /* border: 3px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorall {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    padding: 20px;
}

/* green color */

.colorContainer .greencolor1 {
    background-color: rgba(14, 147, 132, 1);
    left: 20px
}

.colorContainer .greencolor2 {
    background-color: #F0FDF9;
    z-index: 2;
    left: 40px;
}

.colorContainer .greencolor3 {
    background-color: #D7E3E8;
    z-index: 3;
    left: 60px;
}

.colorContainer .greencolor4 {
    background-color: #F7F7F7;
    z-index: 4;
    left: 80px;
}

.colorContainer .blaciandlight1 {
    background-color: rgba(38, 37, 42, 1);
    left: 20px
}

.colorContainer .blaciandlight2 {
    background-color: #BDE556;
    z-index: 2;
    left: 40px;
}

.colorContainer .blaciandlight3 {
    background-color: #EAECF0;
    z-index: 3;
    left: 60px;
}

.colorContainer .blaciandlight4 {
    background-color: #F7F7F7;
    z-index: 4;
    left: 80px;
}

.colorContainer .Bluecolor1 {
    background-color: #0086C9;
    left: 20px
}

.colorContainer .Bluecolor2 {
    background-color: #E0F2FE;
    z-index: 2;
    left: 40px;
}

.colorContainer .Bluecolor3 {
    background-color: #EAECF0;
    z-index: 3;
    left: 60px;
}

.colorContainer .Bluecolor4 {
    background-color: #F7F7F7;
    z-index: 4;
    left: 80px;
}

.colorContainer .purpul1 {
    background-color: #7F56D9;
    left: 20px
}

.colorContainer .purpul2 {
    background-color: #F9F5FF;
    z-index: 2;
    left: 40px;
}

.colorContainer .purpul3 {
    background-color: #EAECF0;
    z-index: 3;
    left: 60px;
}

.colorContainer .purpul4 {
    background-color: #F7F7F7;
    z-index: 4;
    left: 80px;
}

.mainDiv {
    background-color: white;
    height: 93vh;
    width: auto;
    overflow: auto;
    
}

/* blaciandlight */

.custom-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #000;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    outline: none;
}

.custom-checkbox:checked::before {
    content: '✔';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: #000;
    /* background-color:#333333 */
}

.custom-checkbox:checked {
    border-color: #333333;
}

@media screen and (max-width: 980px) {
    .Nameinputdata>div:first-child {
        width: 50%;
    }

    .Nameinputdata .InputField {
        width: 50%;
    }

    .uploadimag>div:first-child {
        width: 50%;
    }

    .Dragger {
        width: 50%;
    }

    .newpassword-fields {
        width: 50%;
    }

    .notifications-btn-parent {
        /* display: flex;
        justify-content: center;
        margin: 10px 0; */
        font-size: 18px;
    }

    .settingTitle {
        font-size: 14px !important;
    }

    .yourprofile h2 {
        font-size: 14px;
    }

    .yourprofile p {
        font-size: 12px;
    }

    .Nameinputdata {
        flex-direction: column;
        align-items: flex-start;
    }

    .Nameinputdata .InputField {
        width: 100%;
    }

    .profileAndButtons {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .yourprofile {
        margin: 10px 0;
    }
    .mainDiv {
        height: auto;
        
    }
}


@media screen and (max-width: 630px) {
    .settingTitle {
        font-size: 12px;
        display: flex;
        margin: auto;
        justify-content: center;
    }

}

@media screen and (max-width: 500px) {

    .Nameinputdata {
        display: flex;
        flex-direction: column;
        gap: 17px;
    }

    .Nameinputdata>div:first-child {
        width: 100%;
    }

    .Nameinputdata .InputField {
        width: 100%;
    }

    .newpassword-fields {
        width: 100%;
    }

    .notifications-btn-parent {
        display: flex;
        justify-content: center;
        font-size: 14px;
    }

    .newpassword-fields span {
        font-size: 12px;
        font-weight: 400;
    }

    .uploadimag {
        flex-direction: column;
        gap: 15px;
    }

    .uploadimag>div:first-child {
        width: 100%;
    }


    .Dragger {
        width: 100%;
    }

    .profileAndButtons {
        display: none;
    }

    .buttonsmobile {
        display: block;
    }

    .buttonsmobile Button:first-child {
        background-color: var(--secondary-color);
        font-size: 14px;
        font-weight: 600;
        min-height: 45px;
        width: 100%;
        margin: 10px auto;
        border-radius: 8px;
        border: 1px solid rgba(16, 24, 40, 0.21);
        color: var(--primary-color)
    }

    .buttonsmobile Button:last-child {
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        min-height: 45px;
        border-radius: 8px;
        margin: 10px auto;
        border: 1px solid rgba(16, 24, 40, 0.21);
        color: rgba(38, 37, 42, 1)
    }

    .buttonsmobile Button:hover {
        border: 1px solid rgba(16, 24, 40, 0.21) !important;
    }
}
.mainDiv {
    /* background-color:var(--secondary-color); */
    height: 90vh;
    margin: 1vh 10px;
    padding: 32px 30px;
    border-radius: 10px;
  }
.wrapper{
    background: #ffffff;
    /* padding: 10px 10px; */
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}
.wrapper .Breadcrumb{
padding-left: 10px;
font-size: 14px;
/* margin: 0 15px; */
cursor: pointer;
font-weight: 500;
color: #475467;
}
.Breadcrumb .right_icon{
    vertical-align: middle;
    color: #D0D5DD;
}
.Breadcrumb .gohome{
    vertical-align: middle;
}
  .Breadcrumb .active_breadCrum{
   font-size: 14px;
   font-weight: 600;
    background-color: #fafbfded;
    padding: 3px 7px;
  }
.wrapper .header{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 1rem;
    border-bottom: none;
}
.wrapper .header .top{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
}
.wrapper .header .top .title{
    color: #26252a;
    font-size: 30px;
    font-weight: 500;
}
.wrapper .header .top .title .decs{
    color: #667085;
    font-size: 16px;
    font-weight: 400;
}
.card_parent{
    overflow-x: auto;
}
.main_parent .card{
    width: 100%;
     height: 66px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.main_parent .card .teacher_performance_card{
    padding: 30px 20px;
}
.loading-comp {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-comp .desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-comp .desktop img {
    width: 100%;
    height: 100vh;
}

.loading-comp .tab {
    display: none;

}

.loading-comp .mob {
    display: none;
}

@media (max-width: 991px) {
    .loading-comp .desktop {
        display: none;
    }

    .loading-comp .tab {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .loading-comp .tab img {
        width: 100%;
        height: 100vh;
    }
    .loading-comp .mob {
        display: none;
    }
}

@media (max-width: 550px) {
    .loading-comp .desktop {
        display: none;
    }

    .loading-comp .mob {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .loading-comp .mob img {
        width: 100%;
        height: 100vh;
    }
    .loading-comp .tab {
        display: none;
    }
}
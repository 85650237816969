.mainDiv {
  background-color: var(--secondary-color);
  height: 90vh;
  margin: 3vh 25px;
  /* overflow-y: scroll; */
  padding: 20px 30px;
  border-radius: 10px;
}

.mainss {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 1rem;
}

.teachers_container {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  border-radius: 8px;
}

.teachers_container .header {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teachers_container .header .header_left {
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 5px;
}

.teachers_container .header .header_left .title_container {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.teachers_container .header .header_left .title_container .title_text {
  font-size: 30px;
  line-height: 30px;
  color: #26252a;
  font-weight: 500;
}

.teachers_container .header .header_left .title_container .title_tag {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  background: #bde55680;
  padding: 5px;
  border-radius: 10px;
}

.teachers_container .header .header_left .desc {
  color: #667085;
  font-size: 16px;
}

.fliters_container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.add_btn {
  padding: 20px !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background: #26252a;
  border: 1px solid #26252a;
}

.add_btn:hover {
  color: #26252a !important;
  background: #ffffff;
  border: 1px solid #26252a !important;
}

.filter_search {
  border: 1px solid #eaecf0 !important;
  padding: 5px auto;
}

.teachers_container .select_container {
  width: 30%;
}

.filter_select {
  height: 47px !important;
  width: 100% !important;
  /* sffsf */
}

.filter_select .ant-select-selector {
  height: 47px !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
}

.filter_input.ant-input {
  height: 47px !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
}
.popover_field {
  margin-bottom: 1rem;
}
.popover_field .submit-btn {
  display: flex;
  justify-self: flex-end;
  background: var(--primary-color);
}
.small-progress {
  width: 150px;
  /* height: 100px; */
}
.assign_class_btn {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.teachers_container {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 20px 25px;
  height: auto;
  width: 100%;
}

.teachers_container .header {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teachers_container .header .header_left {
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 5px;
}

.teachers_container .header .header_left .title_container {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.teachers_container .header .header_left .title_container .title_text {
  font-size: 30px;
  line-height: 30px;
  color: #26252a;
  font-weight: 500;
}

.teachers_container .header .header_left .title_container .title_tag {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  background: #bde55680;
  padding: 5px;
  border-radius: 10px;
}

.teachers_container .header .header_left .desc {
  color: #667085;
  font-size: 16px;
}

.fliters_container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 6px;
}

.btn_assign {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.add_btn {
  padding: 20px !important;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  /* background: #26252a; */
  background: var(--secondary-color) !important;
  /* border: 1px solid #26252a; */
  border: 1px solid var(--secondary-color) !important;
}

.add_btn:hover {
  color: #26252a !important;
  background: #ffffff;
  border: 1px solid #26252a !important;
}

.filter_search {
  border: 1px solid #eaecf0 !important;
  padding: 10px !important;
}

.btn_clear {
  font-size: 15px;
}

.exapandRow {
  height: fit-content;
  overflow: auto;
}

.teachers_container .select_container {
  width: 30%;
}

.filter_select {
  height: 47px !important;
  width: 100% !important;
  /* sffsf */
}

.filter_select .ant-select-selector {
  height: 47px !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
}

.small-progress {
  width: 150px;
  /* height: 100px; */
}

.custom-modal .ant-modal-content {
  background-color: #ffffff !important;
  opacity: 1 !important;
  width: 530px;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  /* top: -40px; */
}

.custom-modal .ant-modal-mask,
.ant-modal-content {
  background-color: #ffffff !important;
}
.student_modal_button {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.form {
  /* border: 1px solid black; */
  position: relative;
  top: -17pxpx;
}
.curriDetails {
  width: 100%;
  outline: none;
  border: 1px solid #e4e4e4;
  padding: 8px;
  border-radius: 6px;
  margin-top: 6px;
  margin-bottom: 14px;
}
.label_curriculum {
  font-weight: 700;
  font-size: 14px;
}

.modalheading {
  position: relative;
  /* border: 1px solid black; */
  top: 9px;
}

.labels {
  font-weight: 700;
  font-size: 14px;
}

h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
.labelinput {
  border: 1px solid #eaecf0;
  width: 100%;
}

.pdiv {
  position: relative;

  top: -37px;
}

.cancelbtn {
  width: 234px;
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.addbtn {
  width: 234px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  color: white;
  border-radius: 8px;
  height: 45px;
}
.class_modal_btn {
  width: 50%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.module_curriculum {
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
}

.addbtn svg {
  color: #fff !important;
}

.modal_addIcon_parent {
  width: 50px;
  display: grid;
  place-items: center;
  height: 50px;
  border-radius: 50%;
  background-color: #eff8ff;
}

.class_modal .class_modal_hdng {
  font-size: 18px;
  color: #26252a;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}

.class_modal .class_modal_subHdng {
  color: #667085;
  font-size: 13px;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
  margin-bottom: 20px;
}

.class_modal .input_label {
  font-size: 15px;
  font-weight: 500;
  font-family: "Plus Jakarta Sans";
  display: inline-block;
  margin-bottom: 7px;
}

.class_modal .progress_classInput_parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.class_modal .btns-parent {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.class_modal .btns-parent button:first-child {
  width: 50%;
  color: #26252a;
}

@media (max-width: 600px) {
  .custom-modal .ant-modal-content {
    width: 100%;
  }
  .curriculum_btn {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ant-space-item {
    width: 100%;
  }
  .modalheading {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    margin-bottom: 6px;
    gap: 15px;
  }
  .modalheading h1 {
    text-align: center;
  }
  .modalheading p {
    text-align: center;
    font-size: 15px;
  }
  .student_modal_button {
    flex-direction: column;
    width: 100%;
  }
  .student_modal_button .cancelbtn {
    width: 100%;
  }
  .student_modal_button .addbtn {
    width: 100%;
  }

  .curriculum_btn Button {
    width: 100%;
  }
}

@media only screen and (max-width: 980px) {
  .teachers_container {
    padding: 10px 20px;
  }
  .mainDiv {
    padding: 15px;
  }
}

.select {
    width: 100% !important;
}
.ant-modal-title {
    font-size: 24px; /* Increase this value to make the title bigger */
    font-weight: bold; /* Optional: Make the title bold */
  }

.modal .form{
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex; 
    flex-direction: column;
    gap: 15px;
}
.parent-btn{
    display: flex;
    gap: 5px;
}
.btn{
    width: 50%;
}
.assign-btn{
    background: var(--secondary-color) !important;
    color: var(--primary-color);
}
.cancel-btn{
    color: black;
    background-color: white;
}
.modal_title{
    font-size: 20px;
    font-weight: 700;
    font-family:"Plus Jakarta Sans" ;
}
.notification-input {
  border-color: #000000;
}

.ant-input {
  border: 1px solid black;
  /* margin-top: 2%; */
}

.ant-input:focus {
  /* border: 1px solid yellow; */
}

.notification-label {
  margin: 5px 0;
}

.select-user {
  width: 100%;
  border: 1px solid #000000;
}

.ant-select-selection-item-remove svg {
  color: #000000 !important;
}

.notifications-bg {
  background: #f3fee7;
  border: 2px solid #bde556;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
}

.notifications-bg-unread {
  background: white;
  border: 2px solid grey;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
}

.loadingGIF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.dot {
  width: 10px;
  border-radius: 50px;
  color: #bde556;
  display: inline;
}

.notification-read {
  /* margin-left: 20px; */
}

.notifications-btn-parent {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.notifications-tabs-content {
  overflow-y: auto;
  padding-right: 16px;
}

.notification-custom-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  /* background-color: black; */
  position: relative;
}

.div#rc-tabs-3-panel-1 {
  height: 100%;
}

div#rc-tabs-3-panel-1 {
  height: 100%;
}

.ant-tabs-content {
  height: 100%;
}
.ant-tabs-tabpane {
  height: 100%;
}

.notification-custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.bellNotification {
  font-size: 70px !important;
}

/* media-query */

/* for > 1024  */

@media screen and (min-width: 1024px) {
  .notification-read {
    margin-left: 20px;
  }
  .bellNotification {
    font-size: 50px !important;
  }
}

/* for 768px */

@media screen and (max-width: 768px) {
  .notification-text {
    font-size: 12px;
  }
  .bellNotification {
    font-size: 40px !important;
  }
  .notification-read {
    margin-left: 0;
  }
}

/* from 576 to 636 */

@media screen and (min-width: 576px) and (min-width: 636px) {
}

/* for 600px */

@media screen and (max-width: 630px) {
  .notifications-btn-parent {
    flex-direction: column;
    justify-content: flex-start;
    margin: 10px 0;
  }
  .notificationHeading {
    text-align: center;
    font-size: 24px !important;
  }
  .notificationbtn {
    width: 160px;
  }

  .notification_text {
    text-align: center !important;
  }
}
@media screen and (max-width: 600px) {
  .notifications-bg-unread,
  .notification-text,
  .notifications-bg,
  .notification-text {
    text-align: center;
  }

  .notifications-bg {
    margin-top: 10px;
  }
  .bellNotification {
    font-size: 40px !important;
  }

  .notification-read {
    margin: 0 auto;
  }
}

@media screen and (max-width: 400px) {
  .notificationHeading {
    font-size: 20px !important;
  }
}

.create-schedule-modal .anticon svg {
  color: #000 !important;
}
.create-schedule-modal .ant-modal-content {
  background: #ffffff !important;
}
.create-schedule-modal ._body ._header {
  margin-bottom: 20px;
}
.create-schedule-modal ._body ._header h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
.create-schedule-modal ._body ._header p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(102, 112, 133, 1);
}

.create-schedule-modal ._body label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.create-schedule-modal ._body .ant-form-vertical .ant-form-item-label {
  padding: 0;
}
.create-schedule-modal ._body .ant-form-item {
  margin-bottom: 12px;
}

.create-schedule-modal ._scheduleFooter {
  display: flex;
  gap: 10px;
}
.create-schedule-modal ._scheduleFooter button {
  flex: 1;
  border-radius: 8px;
  height: 37px;
}
.create-schedule-modal ._scheduleFooter button:nth-child(2) {
  background-color: var(--secondary-color);
  color: #fff;
}
.create-schedule-modal ._scheduleFooter button:nth-child(2):hover {
  background-color: var(--secondary-color);
  color: #fff;
}
.create-schedule-modal ._scheduleFooter .anticon svg {
  color: #fff !important;
}

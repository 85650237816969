.custom_pagination_container {
  width: 100%;
  padding: 1rem 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.custom_pagination_container .page_change_btn {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.custom_pagination_container .disabled_change_btn {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  column-gap: 10px;
  opacity: 0.6;
}

.custom_pagination_container .ant-pagination-prev,
.custom_pagination_container .ant-pagination-next {
  display: none !important;
}

.custom_pagination_container .ant-pagination-item-active {
  background: var(--primary-color)!important;
  border: none !important;
}


@media (max-width: 980px) {
  .custom_pagination_container {
    flex-direction: column;
    align-items: stretch;
  }

  .custom_pagination_container .page_change_btn,
  .custom_pagination_container .disabled_change_btn {
    margin: 20px 0;
    width: 100%;
    justify-content: center;
  }

  .custom_pagination_container .current_page_display {
    text-align: center;
    font-size: 14px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-top: 20px;
    color: #344054;
  }



  .changesBtn {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .custom_pagination_container .ant-pagination-prev, .custom_pagination_container .ant-pagination-next{
      display: none !important;
  }
  
  .custom_pagination_container .ant-pagination-item-active{
      /* background: #bde55680 !important; */
      background: var(--primary-color) !important;
      border: none !important; 
  }
}
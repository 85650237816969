.student_performance_card {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafbfd;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
.AvatarImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.clasDash_loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.class_spin {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border_green {
  position: absolute;
  left: 0;
  /* background: #bde556; */
  background: var(--secondary-color) !important;
  width: 10px;
  height: 100%;
}

.student_performance_card .card_left {
  margin-left: 10px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.student_performance_card .card_left img {
  width: 60px;
  cursor: pointer;
}
.student_performance_card .card_left img:hover {
  opacity: 0.9;
}

.student_performance_card .card_left .info_container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.student_performance_card .card_left .info_container .name {
  font-weight: 600;
}

.student_performance_card .card_left .info_container .bottom {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.student_performance_card .card_left .info_container .bottom .green_circle {
  background: var(--secondary-color);
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

.student_performance_card .card_right {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.student_performance_card .card_right .label {
  color: #26252a;
  font-size: 14px;
  font-weight: 500;
}

/* teacher card */

.teacher_performance_card {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafbfd;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.border_blue {
  position: absolute;
  left: 0;
  background: var(--primary-color);
  width: 10px;
  height: 100%;
}

.teacher_performance_card .class_tag {
  background: #eff8ff;
  border-radius: 30px;
  padding: 5px 1rem;
  color: #175cd3;
}

.teacher_performance_card .card_left {
  margin-left: 10px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.teacher_performance_card .card_left img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.teacher_performance_card .card_left .info_container .name {
  font-weight: 600;
}

.student_class {
  margin-top: 7px;
  font-weight: 500;
}

.report_text {
  color: #26252a;
  font-family: "Plus Jakarta Sans";
  font-size: 30px;
  font-weight: 500;
}
.report_normal_text {
  color: #667085;
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
}

.main_report_parent {
  margin-top: 30px;
}

.spin_loading {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_report h1 {
  color: #26252a;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 600;
}
.card_reports {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.card_report_header_parent {
  border: 1px solid var(--secondary-color);
  border-radius: 6px;
  padding: 14px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.card_report_header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.report_eye {
  display: none;
}
.card_report_header_h1 {
  color: #26252a;
  font-size: 16px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: capitalize;
}
.card_report_header_h1_child {
  width: 300px;
}
.card_report_header p {
  color: #26252a;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
}
.report_card_btn {
  padding: 6px 16px;
  border: 1px solid var(--secondary-color);
  width: fit-content;
  border-radius: 8px;
  cursor: pointer;
  color: var(--secondary-color);
}
.report_dots {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 15px;
  background-color: var(--secondary-color);
}
.report_student_performence {
  /* width: 100px; */
}
.btn_report_head {
  display: flex;
  align-items: center;
  gap: 10px;
}
.report_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.generate_btn {
  border-radius: 6px;
  cursor: pointer;
  padding: 14px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 5px;
}
.report_add {
  font-size: 20px;
}

@media only screen and (max-width: 800px) {
  .report_text {
    font-size: 26px;
  }
  .card_report_header_h1 {
    font-size: 15px;
  }
  .main_report h1 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 600px) {
  .report_text {
    font-size: 22px;
    margin-top: 20px;
  }
  .report_normal_text {
    font-size: 14px;
  }
  .card_report_header_h1 {
    font-size: 14px;
  }
  .main_report h1 {
    font-size: 13px;
  }
  .report_head {
    flex-direction: column;
  }
  .report_text {
    text-align: center;
  }
  .report_normal_text {
    text-align: center;
  }
  .generate_btn {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 430px) {
  .report_text {
    font-size: 19px;
    text-align: center;
  }
  .card_report_header_h1 {
    font-size: 12px;
  }
  .report_card_btn {
    display: none;
  }
  .report_eye {
    display: block;
  }
  .main_report h1 {
    text-align: center;
  }
  .report_normal_text {
    font-size: 13px;
    text-align: center;
  }
}

._UpcomingClassesCard {
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(234, 236, 240, 1);
}
._UpcomingClassesCard ._upcomingHeader {
  width: 100%;
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 15px 20px;
  background-color: rgba(250, 251, 253, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._UpcomingClassesCard ._upcomingHeader > h2 {
  font-size: 14px;
  font-weight: 500;
}
._UpcomingClassesCard ._upcomingHeader > button {
  background-color: rgba(239, 248, 255, 1);
  color: rgba(23, 92, 211, 1);
  border: 1px solid rgba(23, 92, 211, 1);
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  height: 25px;
  padding: 0;
  padding-inline: 12px;
}

._UpcomingClassesCard ._upcomingContent {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}
._UpcomingClassesCard ._upcomingContent ._clockBox {
  width: 38px;
  height: 38px;
  border: 1px solid rgba(236, 238, 245, 1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

._UpcomingClassesCard ._upcomingContent ._scheduleBox h3 {
  font-size: 14px;
  font-weight: 500;
}
._UpcomingClassesCard ._upcomingContent ._scheduleBox p {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

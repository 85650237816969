/* main{
    display: flex;
} */

.custom_card .ant-card-body {
  width: 100% !important;
  display: flex;
  flex-direction: column;

  /* flex-direction: column; */
}

.cardupdiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.cardupdiv h1 {
  margin: 0;
}

.classlabel {
  /* display: flex; */
  position: relative;
  top: 15px;
  padding: 7px;
  border: 1px solid #eaecf0;
  width: 80px;
  border-radius: 4px;
}

.classtext {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.carddowndiv {
  display: flex;
  /* border: 1px solid black; */
  width: 100%;
  height: 38px;
  margin-top: 21px;
  padding: 4px;
  padding-left: 0;
  justify-content: space-between;
}

/* *********************************************************************************** */
._overviewDashboard {
  font-family: "Plus Jakarta Sans";
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

._overviewDashboard::-webkit-scrollbar{
  display: none !important;
}

._overviewDashboard ._leftSectionView {
  width: calc(100% - 300px);
  padding-block: 10px;
}
._overviewDashboard ._leftSectionView ._overviewCard {
  display: none;
}
._overviewDashboard ._leftSectionView ._overviewCard ._singleOverviewCard {
  width: 100%;
}
._overviewDashboard ._leftSectionView ._leftSectionWrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 20px;
}
._overviewDashboard ._rightSection {
  width: 300px;
  height: 100%;
  padding: 20px 10px;
}

._overviewDashboard ._leftSectionWrapper ._mainHeading {
  font-size: 30px;
  font-weight: 500;
}

._overviewDashboard ._leftSectionWrapper ._description {
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
  margin-top: 10px;
}

/* CHART */
._overviewDashboard ._chartSection {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

._overviewDashboard ._chartSection ._chartBox {
  flex: 1;
}

._overviewDashboard ._chartSection ._chartBox ._chartHeading {
  font-weight: 600;
  font-size: 16px;
  color: black;
}

._overviewDashboard ._chartSection ._chartBox ._chart {
  border: 1px solid rgb(234, 236, 240);
  border-radius: 12px;
  margin-top: 10px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}
._chart div .recharts-responsive-container {
  min-width: 340px !important;
}
._chart
  div
  .recharts-responsive-container
  .recharts-wrapper
  .recharts-legend-wrapper {
  position: absolute;
  width: 432px;
  height: auto;
  left: 0px !important;
  top: 7px !important;
}
._chart .gauge-chart .needle-base {
  fill: white; /* White background */
  stroke: black; /* Black border */
  stroke-width: 2; /* Thickness of the border */
}
._chart ._levelHeading {
  display: "flex" !important;
  align-items: "center";
  justify-content: space-between;
  width: 90%;
}
._chart ._levelTop ._levelColor {
  background-color: var(--primary-color);
  width: "20px";
  height: "20px";
  margin-right: "10px";
}

/* Latest Exam Result */
._overviewDashboard ._latestExamResult {
  border: 1px solid rgb(234, 236, 240);
  border-radius: 12px;
  padding: 16px;
  margin-top: 30px;
}

._overviewDashboard ._latestExamResult ._resultHeader {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

._overviewDashboard ._latestExamResult ._resultHeader > h2 {
  font-weight: 600;
  font-size: 16px;
  color: black;
  letter-spacing: -2%;
}

._overviewDashboard ._latestExamResult ._resultHeader > div {
  display: flex;
  gap: 10px;
  align-items: center;
}
._overviewDashboard ._latestExamResult ._resultHeader > div > p {
  font-weight: 400;
  font-size: 16px;
}

._overviewDashboard ._latestExamResult ._resultBody {
  width: 100%;
  margin-top: 20px;
}

/* Latest Achieved Targets */
._overviewDashboard ._latestAchievedTargets {
  border: 1px solid rgb(234, 236, 240);
  border-radius: 12px;
  padding: 16px;
  margin-top: 30px;
}

._overviewDashboard ._latestAchievedTargets ._targetHeader {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

._overviewDashboard ._latestAchievedTargets ._targetHeader > h2 {
  font-weight: 600;
  font-size: 16px;
  color: black;
  letter-spacing: -2%;
}

._overviewDashboard ._latestAchievedTargets ._targetBody {
  margin-top: 10px;
}

/* Right Section */
._overviewDashboard ._overviewDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

._overviewDashboard ._overviewDetails > h2 {
  font-size: 16px;
  font-weight: 600;
}

._overviewDashboard ._overviewDetails ._studentNameBox {
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  padding-inline: 10px;
}
._overviewDashboard ._overviewDetails ._studentNameBox > h3 {
  font-size: 18px;
  font-weight: 600;
}
._overviewDashboard ._overviewDetails > p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -2%;
  margin-top: 10px;
}

._overviewDashboard ._overviewDetails ._studentImage {
  width: 170px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

._overviewDashboard ._overviewDetails ._studentImage img {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
}
._overviewDashboard ._classAndContact {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
._overviewDashboard ._overviewDetails ._classAndContact p {
  font-size: 16px;
  font-weight: 400;
}

._overviewDashboard ._classesProgressSection {
  margin-top: 30px;
}
._overviewDashboard ._classesProgressSection h2 {
  font-size: 16px;
  font-weight: 600;
}

._overviewDashboard ._classesProgressBox {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 1250px) {
  ._overviewDashboard ._chartSection {
    flex-direction: column;
  }
}

@media (max-width: 1380px) {
  ._overviewDashboard ._leftSectionView {
    width: 100%;
  }
  /* ._overviewDashboard ._rightSection {
      display: none;
    } */
  ._overviewDashboard ._leftSectionView ._overviewCard {
    display: flex;
  }
  ._overviewDashboard ._leftSectionWrapper ._description {
    display: none;
  }
}

@media (max-width: 600px) {
  ._overviewDashboard ._leftSectionView ._leftSectionWrapper {
    padding: 10px 20px;
  }
  ._overviewDashboard ._leftSectionWrapper ._mainHeading {
    font-size: 22px;
  }
  ._overviewDashboard ._latestExamResult,
  ._overviewDashboard ._latestAchievedTargets {
    border: none;
    padding: 0px;
  }
}

/* *********************************************************************************** */

@media only screen and (max-width: 668px) {
  .cardupdiv h1 {
    font-size: 13px;
  }
  .tag_report {
    font-size: 15px;
  }
}

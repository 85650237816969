.Custom_tabs_containers {
    display: flex;
    row-gap: 20px;
    margin: 10px auto;
    gap: 15px;
    /* min-width:900px; */
    /* border:2px solid red; */
    overflow: auto;

}

.Custom_tabs_containers {
    display: flex;
    row-gap: 20px;
    margin: 10px auto;
    gap: 15px;
    padding: 10px 0px;
    overflow: auto;

}

.custom_tab {
    cursor: pointer;
    padding: 5px 10px;
    /* width:190px; */
}

.custom-tab-all {
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    min-width: max-content;
    padding: 5px 10px !important;


}

.custom_active_tab {
    cursor: pointer;
    margin-left: 0px;
    background-color: var(--secondary-color) !important;
    color: var(--primary-color);
    /* font-size: 15px;
    font-weight: 500;
    line-height: 20px; */
    /* width: fit-content; */
    border-radius: 6px;
    /* padding: 5px 10px; */

}

.notifications-page-btns {
    text-align: left;
    margin-top: 20px;
    display: flex;
    gap: 13px;
}

.btn-unread {
    color: black;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 6px;
    padding: 5px 10px;
    background-color: #bde55680;

}

.table {
    position: relative;
    top: 21px;
    padding: 15px
}

.custom-title-cell {
    background-color: #EFF8FF;
}





.ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 22px;
}



.ant-table-thead>tr>th {
    background-color: #EFF8FF !important;
    color: var(--text) !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 22px;
}

.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container {
    border-inline-start: 1px solid black;
    border-top: 1px solid black;
}

.anticon svg {
    color: black !important;
}


.addcurriculumbtn {
    background-color: black;
    color: white;
    font-size: 16px;
    font-weight: 600;
}


.btn-active {
    background-color: transparent;
    color: black;
    border: none;
}



@media screen and (max-width: 980px) {
    .custom_active_tab {
        width: fit-content;
    }
}

@media screen and (max-width: 600px) {
    .custom_active_tab {
        width: fit-content;
    }

    /* .Custom_tabs_containers {
        flex-direction: column;

    } */
}
.componentHeading {
  font-size: 29px !important;
  font-weight: 500 !important;
  margin-bottom: 5px;
}

.componentBtnText {
  background-color: var(--secondary-color) !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 20px 12px 20px;
  position: absolute;
  right: 0;
  border-radius: 10px;
  width: 140px;
  margin-top: -35px;
}

.componentPeople {
  font-size: 12px;
  background-color: var(--primary-color) !important;
  padding: 5px;
  border-radius: 10px;
  position: relative;
  top: 8px;
}

.gheader {
  display: flex;
  align-items: center;
  gap: 20px;
}

.gheader .people {
  border-radius: 10px;
  font-size: 12px;
  font-family: "Plus Jakarta Sans";
  color: #26252a;
  font-weight: normal;
}
.text {
  color: #667085;
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
}
.text-parent {
  max-width: 650px;
}

.gheader .heading {
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
  font-size: 30px;
  color: #26252a;
}

.gheader .people-gheader {
  padding: 3px 13px;
  border-radius: 20px;
  background-color: var(--primary-color) !important;
}

.gheaderMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: start;
  padding-bottom: 14px;
}
.header-btn {
  background-color: var(--secondary-color);
  color: white;
}

@media only screen and (max-width: 980px) {

  .gheader .heading {
    font-size: 25px;
  }
  .text-parent p {
    margin-bottom: 20px;
    text-align: center;
  }
  .text {
    font-size: 16px;
  }
  

}


@media only screen and (max-width: 700px) {
  .gheaderMain {
    flex-direction: column;
    margin: auto;
  }
  .btn_top{
    width: 100%;
  }
  .gheaderMain {
    width: 100%;
  }
  .gheader-c {
    width: 100%;
  }
  .text {
    font-size: 15px;
  }
    .header-btn {
    width: 100%;
  }
  .gheader{
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  
}

@media only screen and (max-width: 600px) {


  .gheader .heading {
    font-size: 20px;
  }
}
@media only screen and (max-width: 450px) {
  .gheader{
    flex-direction: column;
  }
  .text{
    font-size: 14px;
  }
}

._targetCard {
  border-top: 1px solid rgb(234, 236, 240);
  display: flex;
  align-items: center;
  gap: 10px;
  padding-block: 16px;
}

._targetCard ._iconBox {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(224, 242, 254, 1);
  border-radius: 50%;
}
._targetCard ._content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

._targetCard ._lessonText {
  flex: 1;
  font-size: 14px;
  font-weight: 500 !important;
  letter-spacing: -2%;
  color: black;
}

._targetCard ._rightContent {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
._targetCard ._rightContent > p {
  font-weight: 400;
  font-size: 14px;
}

._targetCard ._rightContent > div {
  display: flex;
  gap: 10px;
  align-items: center;
}

._targetCard ._rightContent > div > p {
  font-weight: 400;
  font-size: 14px;
}

@media (max-width: 1250px) {
  ._targetCard ._content {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}
@media (max-width: 600px) {
  ._targetCard ._rightContent > p,
  ._targetCard ._rightContent > div > p {
    font-size: 12px;
  }
}

.assesment-heading{
    margin-top: 3px;
    margin-bottom: 5px;
    font-size: 30px;
}

.random-text{
    font-size: 14px;
    text-align: left;
    font-weight:400 !important;
}

.text-btn-container{
    text-align: center;
}

.create-exam-title{
    margin-top: 5px;
}

.top-corner-create-exam{
    margin-top: 10px;
    /* background-color: #000000 !important; */
    background-color: var(--secondary-color) !important;
    color:#FFFFFF;
}

.create-exam-btn{
    margin-top: 10px;
    padding:"0 80px";
    border: 1px solid dotted !important; 
    
}

.btn-plus{
    position: relative;
    top: 2px;
    right:  10px;

}

.top-plus-btn{
    position: relative;
    top:1px;
    right: 5px;
}

.ant-layout .assessment-random{
    text-align: center !important;
}
/* Container Styles
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
    padding: 0 20px;
    box-sizing: border-box; 
  }
  
 
  .signupheading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .pl {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .labels {
    margin-bottom: 1rem;
  }
  
  .input {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
  }
  
  .button {
    width: 100%;
    background-color: #1890ff;
    color: white;
  }
  
  .button:hover {
    background-color: #40a9ff;
    color: white;
  }
  
  .ant-form-item {
    margin-bottom: 1.5rem;
  }
  
  .ant-select,
  .ant-select-selector {
    width: 100%;
  }
  
  .ant-upload-list-picture .ant-upload-list-item-thumbnail {
    width: 100%;
    height: auto;
  }
  
  .ant-checkbox-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .space {
    display: flex;
    justify-content: center;
  }
  
  p {
    text-align: center;
  }
  
  strong {
    cursor: pointer;
    color: #1890ff;
  }
  
  strong:hover {
    text-decoration: underline;
  }
   */

   .signup-teacher-logo{
    width: 200px;
    height: auto;
    margin: 20px 0 0 20px;
   }

   .signup-teacher-heading{
    margin-top: 15px;
    margin-bottom: 0 !important;
    margin-left: 210px;
    font-size: 30px;
   }

   .signup-teacher-form{
    width: 50%;
    margin: 10px auto;
   }

   .signup-teacher-image{
    width: 60%;
   }

   .first-name, .last-name, .signup-teacher-password, .enter-your-phone-number, .signup-teacher-password, .signup-teacher-button{
    width: 100%;
   }


   .signupCol-1{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .signup_btn{
    border-radius: 8px;
    height: 44px;
    width: 100%;
    background-color: var(--secondary-color);
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 700;
    padding: 6px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    width: 100%;
   }

   /* Media Query */

   @media screen and (min-width:768px) and (max-width:974px){
    .signup-teacher-form{
      width: 50%;
      margin: 0 0 0 25px;
     }

     .signup-teacher-salutation{
      width: 100%;
     }
   }

   @media screen and (max-width:768px){
    .signup-teacher-image{
      display: none;
    }
  .signUpForm{
    width: 100%;
  }
   }



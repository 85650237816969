@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

.main {
  width: 100%;
  height: 100vh;
  overflow-x: clip;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.signupform {
  color: #667085;

  /* position: relative; */
  /* left: 10px; */
  /* top: 35px; */
  display: flex;
  flex-direction: column;
  /* width: 95%; */
  /* height: 100%; */
}

.left {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100vh;
  background-color: #f7f7f7;
  /* overflow-y: clip; */
}

.right {
  height: 100vh;
  overflow-y: hidden;
  background-color: white;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main ._headingBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.main ._headingBox h1 {
  font-size: 36px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.signupheading {
  font-size: 28px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  line-height: 44px;
  text-align: center !important;
  /* left: 200px; */
  /* position: relative; */
}

.button {
  border-radius: 8px;
  height: 44px;
  width: 100%;
  background-color: var(--secondary-color) !important;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  padding: 6px;
}

.input {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding-left: 12px;
  background-color: #fff !important;
}
.loginInput:hover{
  background-color: red;
}
input:focus-visible{
outline: none;
border: 1px solid var(--secondary-color);
}
.innerdiv {
  position: absolute;
  top: 50%;
  right: 0;
  left: 400px;

  transform: translate(-50%, -50%);
  height: 450px;
  width: 600px;
  background-color: #f7f7f7;
  border-radius: 12px;
}

p {
  text-align: left;
}

.screenimg {
  width: 60%;
}

.labels {
  font-weight: 700;
  font-size: 14px;
  color: #26252a;
}
.labels.labels-forgot{
  cursor: pointer;
}

.pl {
  /* display: flex; */
  width: 100%;
  font-size: 17px;
  margin: 20px 0;
  text-align: center;
  /* position: relative; */


  /* left: -70px; */
}

.back-to-login {
  cursor: pointer;
  font-weight: bold;
}

.back-to-login:hover {
  border: none !important;
}

.salutationDropdown
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #fff !important;
}
.salutationDropdown .ant-select-single {
  height: 44px !important;
}



@media screen and (max-width:780px) {
  .signupheading {
    font-size: 28px;
  }
  .pl{
    font-size: 15px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width:500px) {
  .signupheading {
    font-size: 22px;
  }
  
}
@media screen and (max-width:400px) {
  .signupheading {
    font-size: 18px;
  }
  .pl{
    font-size: 13px;
  }
}
.overviewHeading{
    font-size: 38px;
    font-weight: 400;
    margin-bottom: 0;
}

.reviewAndManage{
    font-size: 16px;
    color: #667085;

    line-height: 30px;
    font-weight: 400;
}

.studentBrief{
    font-size: 18px;
    font-weight: 600;
}

.icon-plus-btn{
    position: relative;
    top:2px;
    margin-right: 5px;
}

.project-collapse{
    background-color: #fafbfd !important;
    font-size: 14px !important;
    margin-top: 20px;
    border: none;
}

.hide-bottom-border{
  border-bottom: none !important;
}


.content-one{
    font-size: 14px;
}

.contentTwoParent, .contentThreeParent{
    margin-top: 10px;
}

.collapseEditBtn, .collapseDeleteBtn{
    border: none;
}

.ant-collapse-header {
font-size: 16px !important;  
}

.ant-collapse-arrow {
   position: absolute;
   right: 20px;
  }

  .studentOverviewContainer{
    padding:20px
  }

  .outlineMonitor , .luBook, .slCalender, .bsCollectionPlay, .riLinkM{
    position: relative;
    right:10px;
    background-color: #eff8ff;
    border-radius: 50px;
    width:30px;
    
  }

  .second-content, .third-content{
    margin-top: 8px;
  }

  .anchor-tag{
    margin-left: 5px;
  }

  .back-btn, .edit-btn{
    width:100%;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
  }

  .back-btn{
    border: 1px solid #000000;
  }

  .edit-btn{
    background-color: #000000;
    color: #FFFFFF;
  }

  .edit-back-parent{
    margin-top: 50px;
  }

  .second-row, .third-row, .fourth-row{
    margin-top: 10px;
  }

  .head-one, .head-two, .head-three, .head-four, .para-one, .para-two, .para-three, .para-four{
    font-size: 14px;
  }

  .label-bg-color{
    background-color: red;
    display: block;
  }
  
  .all-letters{
    font-size: 25px;
    margin-top: 35px;
    margin-bottom: 0;
  }

.manage-target{
    color: #667085;
    margin-top: 0px;
}

.about-the-target{
    margin-top: 20px;
    margin-bottom: 15px;
}

.clock-and-text{
    background-color: #EFF8FF;
    text-align: center;
    color: #175CD3;
    font-weight: normal;
    padding: 3px;
    border-radius: 5px;
}

.lu-clock{
    margin-top: 2px;
}

.main-container{
  /* margin-left: 15px; */
  padding-left: 15px;
  background-color: #FFFFFF;
  height:100%;
}

  /* Media query */

  @media screen and (min-width:1024px){
    .addCategory{
        background-color: #000000;
        color:#ffffff;
        margin-left: 12px;
    }
  }
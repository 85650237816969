.support_main {
  display: flex;
  gap: 15px;
  height: 100%;
}

.support_left {
  flex: 2;
  -webkit-box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
  -moz-box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
  box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
  height: 100%;
  border-radius: 7px;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.support_right {
  flex: 5;
  -webkit-box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
  -moz-box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
  box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
  padding: 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* height: 80vh; */
}

.input-Search-support .search-input {
  border: 1px solid #ddd;
}

.support_left_child {
  margin-top: auto;
  height: 100%;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.image_support {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile_support {
  display: flex;
  gap: 10px;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  align-items: center;
  padding-inline: 10px;

  svg {
    color: red;
  }
}

.chat_parent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.chat_name {
  font-weight: 600;
  color: #000;
}

.chat_messages {
  font-size: 12px;
}

.support_right_main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  /* height: 70vh; */
}

.message_parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7px;
  border-radius: 5px;
  margin-top: auto;
}

.message_input {
  border: none;
  outline: none;
  width: 100%;
}

.iosend {
  font-size: 20px;
  cursor: pointer;
}

.support_chat_profile {
  padding: 10px;
  width: 100%;
  -webkit-box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
  -moz-box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
  box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
  border-radius: 8px;
}

.support_chat_profile p {
  font-weight: 700;
  font-size: 18px;
}

.support_chat_profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.support_chat_profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.support_messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height:60vh; */
  gap: 17px;
  overflow-x: hidden;
  overflow-y: auto;
}

.message_profile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.message_parent {
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.send-message {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.send-message-modal {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin: 5px auto;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid rgba(16, 24, 40, 0.21);
}

.support_message_text {
  max-width: 50%;
  word-wrap: break-word;
  padding: 9px;
  border-radius: 6px;
  background-color: var(--secondary-color);
}

.teacher .support_message_text {
  max-width: 50%;
  word-wrap: break-word;
  padding: 9px;
  border-radius: 6px;
  background-color: var(--secondary-color);
}

.support_message_text_right {
  max-width: 50%;
  word-wrap: break-word;
  padding: 9px;
  border-radius: 6px;
  background-color: var(--primary-color);
}
.support_img_teacher {
  max-height: 300px;
  max-width: 400px !important;
  /* width:max-content !important; */
  padding: 3px !important;
  display: flex;
  justify-content: flex-end;
}
.support_img_admin,
.support_img {
  max-height: 300px;
  max-width: 400px !important;
  /* width:max-content !important; */
  padding: 3px !important;
  /* display: flex;
    justify-content: flex-start; */
}
.support_t_left img,
.support_img_admin img,
.support_img_teacher img {
  max-height: 300px;
  /* max-width: 100%; */
  /* width:max-content !important; */
  padding: 3px !important;
  /* display: flex;
    justify-content: flex-start; */
}
.support_img-left {
  max-height: 300px;
  max-width: 400px !important;
  /* width:max-content !important; */
  padding: 3px !important;
  display: flex;
  justify-content: flex-end;
  max-height: 200px;
}
.support_img_admin img,
.support_img-left img {
  width: 100%;
}

.message_parent img {
  max-width: 50%;
  border-radius: 10px;
}
.teacher .support_message_text_right {
  max-width: 50%;
  word-wrap: break-word;
  padding: 9px;
  border-radius: 6px;
  background-color: var(--primary-color);
}

.message-right {
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.support_message_text p {
  color: var(--primary-color);
  font-size: 16px;
}

.support_message_text_right p {
  color: var(--secondary-color);
  font-size: 16px;
}

.support_user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.support_user_parent {
  align-items: center;
  gap: 16px;
  display: none;
  overflow: hidden;
  overflow-x: auto;
}

.support_user p {
  font-size: 12px;
}

.support_user {
  display: flex;
  gap: 6px;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
}

.support_user_active {
  background-color: var(--primary-color);
  color: white;
}

.message_user_to_select {
  flex: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.chat_user {
  font-size: 50px;
  color: var(--secondary-color);
}

.message_user_to_select p {
  font-size: 15px;
}
.yoursupport-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid green */
}

.uploadimag-support {
  width: 100%;
}

.uploadimag-support h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.uploadimag-support > div:first-child {
  width: 100%;
  margin: 10px;
}

.Dragger-support {
  width: 100%;
}
.custom_dragger-support {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5px;
}
.custom_dragger-support .uploadicon {
  padding: 10px;
  border: 1px solid rgba(16, 24, 40, 0.21);
  border-radius: 8px;

  font-size: 45px;
}

.custom_dragger-support .ant-upload-drag {
  border: 2px dashed rgba(16, 24, 40, 0.21);
  width: 100%;
}

.custom_dragger-support .ant-upload-drag:hover {
  border: 2px dashed rgba(16, 24, 40, 0.21) !important;
}

.custom_dragger-support .uploadicon {
  padding: 10px;
  border: 1px solid rgba(16, 24, 40, 0.21);
  border-radius: 8px;
  font-size: 45px;
}

.uploaded_image_container-support {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 2px dashed rgba(16, 24, 40, 0.21);
  border-radius: 8px;
}

.uploaded_image_container-support img {
  width: 250px;
  height: auto;
  border-radius: 5%;
}

.uploaded_image_container-support .icon_container {
  cursor: pointer;
}

.uploaded_image_container-support .icon_container svg {
  color: red;
}

.sendButtons-support {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.img_download_btn {
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--secondary-color);
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: var(--primary-color);
  font-family: "Plus Jakarta Sans", sans-serif;
}

.img_download_btn:hover {
  color: var(--primary-color);
}

.image_hover:hover .img_download_btn {
  display: flex !important;
}

@media screen and (max-width: 1024px) {
  .support_left {
    flex: 4;
  }

  .mainDiv-support {
    height: 100vh !important;
  }
}

@media screen and (max-width: 900px) {
  .support_left {
    flex: 3;
  }

  .support_chat_profile p {
    font-size: 17px;
  }

  .chat_user {
    font-size: 40px;
  }

  /* .teacher .support_message_text_right {
        width: 70%
    }

    .teacher .support_message_text {
        width: 70%
    } */
}

@media screen and (max-width: 750px) {
  .uploaded_image_container-support img {
    width: 150px;
    height: auto;
    border-radius: 5%;
  }
  .support_left {
    flex: 0 1;
    gap: 6px;
    padding: 0px;
    box-shadow: none;
  }

  .input-Search-support {
    -webkit-box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
    -moz-box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
    box-shadow: 0px 1px 8px 1px rgba(186, 186, 186, 0.37);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select .ant-select-selector {
    border: none !important;
  }

  .support_user_parent {
    display: flex;
  }

  .support_main {
    flex-direction: column;
    gap: 6px;
  }

  .support_right {
    height: 60vh;
  }

  .support_chat_profile p {
    font-size: 16px;
  }

  .message_user_to_select p {
    font-size: 14px;
  }

  .chat_user {
    font-size: 30px;
  }

  .support_left_child {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .child_wraper {
    display: flex;
    flex-direction: row;
    min-width: min-content;
    align-items: center;
    gap: 30px;

    /* width: 60px;
        height: 60px; */
  }

  .profile_support {
    display: flex;
    flex-direction: column;
    gap: 1px;
    justify-content: center;
    padding: 10px 10px;
    /* width: 50px;
        height: 50px;
        border-radius: 100%; */
    align-items: center;
    /* border-top: 1px solid #ddd; */
    border: none;
  }

  .profile_support img {
    padding: 2px;
    border: 1px solid #ddd;
  }

  .input-Search-support .ant-select {
    margin-bottom: 0px !important;
  }

  .chat_messages {
    display: none;
  }

  .support_user_active {
    border-radius: 12px;
  }
}

@media screen and (max-width: 600px) {
  .support_right {
    height: 70vh;
  }

  .support_chat_profile p {
    font-size: 15px;
  }

  .message_user_to_select p {
    font-size: 11px;
  }

  .support_chat_profile img {
    width: 30px;
    height: 30px;
  }
}

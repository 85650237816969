.exam_heading {
  font-size: 26px;
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
}
.exam_name {
  font-size: 16px;
  font-family: "Plus Jakarta Sans";
  margin-block: 5px;
  /* font-weight:600; */
}
.exam_date {
  font-size: 16px;
  font-family: "Plus Jakarta Sans";
  /* font-weight:600; */
}
.table_details {
  padding-top: 50px;
}
.EmptyData {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  align-items: center;
}
.main_details {
  height: 40vh;
}

._loadingSpinner {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(234, 236, 240, 1);
  margin-top: 30px;
}
._loadingSpinner2 {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
._noDataFound {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._emptyText {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(234, 236, 240, 1);
  margin-top: 30px;
}
._emptyText p {
  font-size: 16px;
  /* font-weight: 600; */
}

/* DESKTOP */
._progressContainer {
  width: 100%;
  border-radius: 20px;
  padding: 32px 32px 32px 32px;
  background-color: #fff;
  margin-top: 10px;
  height: calc(100% - 10px);
  position: relative;
}

.zoomInOutBox {
  display: flex;
  gap: 5px;
  position: absolute;
  top: 19px;
  right: 45px;
  z-index: 999;
}

._progressContainer ._progressHeader {
  width: 100%;
  display: flex;
  gap: 10px;
}

._progressContainer ._progressHeader ._headingBox {
  flex: 1;
}
._progressContainer ._progressHeader ._headingBox h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}
._progressContainer ._progressHeader ._headingBox p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
}

._progressContainer ._progressHeader ._searchInput {
  width: 294px;
  height: 40px;
  background-color: rgba(250, 251, 253, 1);
  border: none;
  border-radius: 8px;
}
._progressContainer ._progressHeader .ant-input {
  height: initial !important;
}

._progressContainer ._progressHeader ._buttonGroup {
  display: flex;
  width: 136px;
  height: 40px;
  background-color: var(--secondary-color);
  border-radius: 8px;
  padding: 8px 6px 8px 6px;
  /* gap: 10px; */
}
._progressContainer ._progressHeader ._buttonGroup button {
  height: 100%;
  font-size: 12px;
  padding: 0;
  padding-inline: 7.5px;
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid transparent !important;
}
._progressContainer ._progressHeader ._buttonGroup .active {
  height: 100%;
  font-size: 12px;
  padding: 0;
  padding-inline: 7.5px;
  background-color: var(--primary-color) !important;
  color: #000 !important;
  border: 1px solid var(--primary-color) !important;
}

._progressContainer ._progressHeader ._addClassBtn {
  background-color: var(--secondary-color) !important;
  border-radius: 8px;
}
._progressContainer ._progressHeader ._addClassBtn:hover {
  color: #fff !important;
}

._subjectContainer {
  width: 100%;
  display: flex;
  gap: 10px;
  overflow: auto;
  margin-top: 30px;
}

._subjectContainer ._subjectButton {
  padding: 7px 10px;
  border-radius: 6px;
  cursor: pointer;
}

._subjectContainer ._subjectButton p {
  color: rgba(102, 112, 133, 1);
  font-weight: 600;
  font-size: 14px;
}

._subjectContainer ._subjectButtonActive {
  padding: 7px 10px;
  border-radius: 6px;
  background-color: var(--secondary-color);
  cursor: pointer;
}
._subjectContainer ._subjectButtonActive p {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
}

section {
  width: 100%;
  /* margin-top: 30px;
  margin-bottom: 30px; */
  display: flex;
  padding-block: 30px;
}

._userSection {
  width: 180px;
}
._progressSection {
  width: calc(100% - 180px);
  height: inherit;
  overflow-x: auto;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  position: relative;
}

._userSection ._top {
  height: 288px;
  background-color: rgba(250, 251, 253, 1);
  border-top-left-radius: 10px;
  padding: 20px;
}
._userSection ._top button {
  background-color: black;
  color: #fff;
}
._userSection ._top button:hover {
  background-color: black;
  color: #fff;
}

._userSection .hintBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;

  > div {
    display: flex;
    /* align-items: center; */
    gap: 5px;

    span:nth-child(1) {
      width: 15px;
      height: 15px;
      display: inline-block;
      border-radius: 4px;
      border: 1px solid rgb(233, 232, 232);
    }
    p {
      flex: 1;
    }
  }
}

._userSection ._userBox {
  height: 42px;
  padding: 16px 24px 16px 24px;
}
._userSection ._userBox p {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgba(38, 37, 42, 1);
}

._levelContainer {
  height: 100%;
  display: flex;

  position: sticky;
  z-index: 999;
}
._levelContainer ._levelBox {
  height: 100%;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
._levelContainer ._levelBox span {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  line-height: 1;

  writing-mode: tb;
  transform: rotate(180deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(180deg);
  /* Firefox */
  -moz-transform: rotate(180deg);
  /* IE */
  -ms-transform: rotate(180deg);
  /* Opera */
  -o-transform: rotate(180deg);
  /* filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); */
}

._levelContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}
._levelContent ._lcHeader {
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-top-right-radius: 10px; */
}
._levelContent ._lcHeader p {
  font-size: 12px;
  font-weight: 500;
  /* color: #fff; */
}

._levelContentBody {
  flex: 1;
  display: flex;
}

._hCard {
}
._hCard ._hCardHeader {
  min-width: max-content;
  height: 34px;
  padding-inline: 30px;
  background-color: rgba(250, 251, 253, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(234, 236, 240, 1);
  border-bottom: 1px solid rgba(234, 236, 240, 1);
}
._hCard ._hCardHeader p {
  font-size: 12px;
  font-weight: 500;
  /* color: black !important; */
}

._hCard ._hCardContent {
  display: flex;
  border-left: 1px solid rgba(234, 236, 240, 1);
}
._hCard ._hCardContent ._vCard {
  width: 40px;
  display: flex;
  flex-direction: column;
}
._hCard ._hCardContent ._vCard ._vCardHeader {
  width: 40px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(234, 236, 240, 1);
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  cursor: pointer;
}

._vCard ._vCardHeader > p {
  font-size: 12px !important;
  font-weight: 500 !important;
  margin: 0;
  line-height: 1;
  letter-spacing: initial;
  color: black !important;
  user-select: none;

  writing-mode: tb;
  transform: rotate(180deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(180deg);
  /* Firefox */
  -moz-transform: rotate(180deg);
  /* IE */
  -ms-transform: rotate(180deg);
  /* Opera */
  -o-transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

._vCard ._studentColorBox {
  width: 40px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(234, 236, 240, 1);
  border-bottom: 1px solid rgba(234, 236, 240, 1);
  position: relative;
}
._vCard ._studentColorBox ._cornerNoteSign {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid;
  border-left: 10px solid transparent;
  top: 0;
  right: 0;
}

/* MOBILE */
._mProgressContainer {
  width: 100%;
  background-color: #fff;
  height: calc(100% - 10px);
}

._mProgressContainer ._progressHeader {
  width: 100%;
  display: flex;
  gap: 10px;
}

._mProgressContainer ._progressHeader ._headingBox {
  flex: 1;
}
._mProgressContainer ._progressHeader ._headingBox h2 {
  font-size: 30px;
  font-weight: 500;
}
._mProgressContainer ._progressHeader ._headingBox p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
}

._mProgressSection {
  width: 100%;
  height: inherit;
  overflow-x: auto;

  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

._nextPrevButton {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
}
._nextPrevButton button {
  flex: 1;
  height: 36px;
  border-radius: 8px;
  border: 1px solid rgba(234, 236, 240, 1);
  font-size: 12px;
  font-weight: 600;
}

@media (max-width: 320px) {
  ._nextPrevButton {
    flex-direction: column;
  }
  ._nextPrevButton button {
    flex: initial;
    width: 100%;
  }
}

/* SINGLE STUDENT CARD */
._singleStudentCard {
  margin-top: 30px;
  height: 66px;
  border-radius: 8px;
  border-left: 8px solid var(--secondary-color);
  background-color: rgb(250, 251, 253);
  padding-inline: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.btn_std_modal {
  background-color: var(--secondary-color) !important;
}
._singleStudentCard ._imageBox {
  width: 45px;
  height: 45px;
}
._singleStudentCard ._imageBox > img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}
._singleStudentCard ._details {
  flex: 1;
  min-width: 0;
}
._singleStudentCard ._details h2 {
  font-size: 14px;
  font-weight: 600;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}
._singleStudentCard ._details > div {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 8px;
  flex-wrap: wrap;
}
._singleStudentCard ._details > div p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(38, 37, 42, 1);
  margin: 0;
  line-height: 1;
}
._singleStudentCard > button {
  /* width: 113px; */
  height: 30px;
  background-color: black;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

._statusButtonBox {
  display: flex;
  align-items: center;
  gap: 10px;
}
._statusButtonBox button {
  font-size: 14px;
  font-weight: 600;
}

._mobileSaveBtnBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

._mobileSaveBtnBox button {
  background-color: black;
  color: #fff;
}
._mobileSaveBtnBox button:hover {
  background-color: black;
  color: #fff;
}

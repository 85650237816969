.addiconcustom{
    
}
.anticon svg {
    /* color: #ffffff !important; */
}
.whiteicon .anticon svg {
    color: white !important;
}
.curriculumDetailLevel .ant-collapse-header .ant-collapse-arrow{
    margin-right: 60px;
}
.ant-collapse-extra{
    display: inline-flex;
    gap: 5px;
    text-align: center;
}
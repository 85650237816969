.std_reports_main{
    display: flex;
    align-items: center;
    gap: 30px;
}
.std_reports_text{
    color:#26252A;
    font-size: 14px ;
    font-weight: 600;
    font-family: "Plus Jakarta Sans";
    cursor: pointer;
}
.std_reports_head_btn{
    padding: 7px;
    border-radius: 8px;
    background-color: #26252A;
    color: white;
}
.report_std_head{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select_btn_student{
    width: 240px;
}
.report_std_head_text{
    font-size: 30px;
    color: #26252A;
    font-family: "Plus Jakarta Sans";
    text-transform: capitalize;
    font-weight: 500;
}

/* .speedo-segment{
    width: 10px !important;
} */
.report_std_head_text_normal{
    color: #667085;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    font-family: "Plus Jakarta Sans";
}
.report_card_student {
    margin-top: 20px;
    background-color: #FAFBFD;
    padding: 10px;
    border-left-color: var(--secondary-color);
    border-left-width: 8px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    gap: 15px;
    border-left-style: solid;
    border-top: none;
    border-bottom: none;
    border-right: none;
}
.report_card_student img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.std_img_text{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.std_text_h1{
    color: #26252A;
    font-size: 14px;
    font-family: "Plus Jakarta Sans";
    font-weight: 600;
}
.std_text_p{
    color: #26252A;
    font-size: 14px;
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
}
.std_speedo_head{
    display: flex;
    align-items: center;
    margin-top: 30px;
    gap: 10px;
}
.std_speedo_head_child{
    width:100%;
    margin: auto;
}
.std_speedo_head_childs{
    border: 1px solid #EAECF0;
    padding: 10px;
    border-radius: 5px;
}
.std_reports_btn_parent{
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-y: scroll;
    scrollbar-width: none;
    overflow-x: none;
}
.marT{
    margin-top: 45px;
}
.std_speedo_head_child1{
    display: flex;
    justify-content: center;
    align-items: center;
}
.report_level{
    color: #26252A;
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: "Plus Jakarta Sans";
}
.report_student_sub{
    display: flex;
    align-items: center;
    gap: 6px;
}
.report_student_sub_single{
    padding: 6px 14px;
    /* margin: ; */
    border-radius: 5px;
    border: 1px solid rgb(198, 197, 197);
}
.std_reports_btn{
    padding: 8px 16px; 
    border: 1px solid rgb(231, 231, 231);
    border-radius: 6px;
    cursor: pointer;
}
.std_reports_btn_active{
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.std_reports_icon{
    font-size: 23px;
}
.report_generate_spin{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media screen and (max-width:550px) {
    .report_std_head{
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .report_std_head_text{
        text-align: center;
    }    
    .report_std_head_text_normal{
        text-align: center;
    }
    .select_btn_student{
        width: 100%;
    }
    .select_btn_student{
        width: 100% !important;
    }
    .select_btn_student_parent{
        width: 100%;
    }
    .std_reports_text{
        font-size: 12px;
        cursor: pointer;
    }
    .std_reports_main{
        gap: 10px;
    }
    .std_reports_icon{
        font-size: 18px;
    }
}
@media screen and (max-width:750px) {
    .std_speedo_head{
        flex-direction: column;
    }
    .std_speedo_head_child {
    width: 100%;
    }
    
}
@media screen and (max-width:1024px) {
    .select_btn_student{
        width: 200px;
    }
}
/* styles.css */
.siderMenu {
  background-color: #f7f7f7;
}
.collapse-siderMenu {
  background: white;
  border-radius: 20px;
  margin-inline: 5px;
}

.collapse-siderMenu .ant-menu::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar {
  height: calc(100vh - 100px); /* Adjust this as necessary */
  margin-top: 20px;
  overflow-y: scroll;
  /* background-color: black; */
  position: relative;
  top: 50px;
}

.notifications-count {
  margin-left: 15px;
  height: 21px;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: none;
}

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

.create-announcement-parent {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.RecentAnnouncment Button {
  display: flex;
  align-items: center;
}
.ant-tabs.ant-tabs-top.css-dev-only-do-not-override-m7ug2s {
  height: 100%;
}
.notifications-tabs-content.notification-custom-scrollbar {
  height: 65vh;
}
.mian_tab {
  flex: 1;
}
.btn_notification_modal {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.btn_notification_modal:hover {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}
.main_parent {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100%;
}

.main_pagination {
  margin-top: auto;
}

.announcement-table {
  overflow: scroll;
  width: 100%;
  margin-top: 20px;
}

.notification-title,
.notification-type,
.user-role {
  width: 150px;
  text-align: left;
  font-size: 2px;
}

/* media query */

@media screen and (max-width: 980px) {
  .responsive {
    padding: 30px !important;
  }
}

@media screen and (max-width: 900px) {
  .mainDiv {
    margin: 0%;
    padding: 0%;
  }
}

/* for 636px */

@media screen and (max-width: 636px) {
  .create-announcement-parent {
    flex-direction: column-reverse;
    text-align: center;
  }

  .RecentAnnouncment Button {
    display: block;
  }
  .responsive {
    padding: 15px !important;
  }
}
@media screen and (max-width: 430px) {
  .responsive {
    padding: 10px !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
.ant-layout {
  background-color: #f7f7f7;
  /* background-color: transparent; */
}

.ant-layout-sider {
  background-color: var(--siderBg) !important;
  position: fixed;
}
.ant-menu {
  background-color: var(--siderBg) !important;
  /* position: fixed; */
}
.ant-menu {
  overflow-y: auto;
  padding-inline: 10px;
}

/* .ant-menu::-webkit-scrollbar {
  width: 5px;
} */

.ant-menu .ant-menu-item-selected {
  /* background-color: black; */
  background-color: var(--secondary-color) !important;
  color: white;
}

.ant-menu .ant-menu-item-selected .ant-menu-title-content {
  color: white;
}

.card {
  /* width: 80%; */
  width: 248px;
  height: 56px;
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-size: small;
  border-radius: 2px;
  background-color: white;
  z-index: 99;

  border-radius: 10px;
  padding: 4px;
  column-gap: 10px;
  margin-top: 6px;
  align-items: center;
}
.cardimage {
  position: relative;
  align-items: flex-start;
  display: flex;
  margin-left: -53px;
  width: 40px;
  height: 40px;
  /* margin-top: 3px; */
  /* left :20px; */
}

.cardicon {
  position: absolute;
  margin-left: 160px;
  margin-top: 0px;
}

.cardtext {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 3px;
  margin-top: 1px;
  height: 40px;
}

.ant-menu .ant-menu-title-content {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  color: black;
  font-size: 16px;
  line-height: 24px;
}

.sider_top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  top: 27px;
}

.sider_top .newLogoDiv {
  display: flex;
  align-items: center;
  gap: 13px;

  img {
    width: 23px;
    height: auto;
  }
  h3 {
    font-weight: 700;
    font-size: 17px;
    letter-spacing: 0px;
    color: #5b5a5c;
  }
}

/* .sider_top .logo {
    width: 100px;
  } */
.sider_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 15px;
}

/* HEADER */
.ant-layout-header {
  background-color: var(--headerBg) !important;
  border-bottom: 1px solid var(--border);
  align-items: center;
}
.layout_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.layout_header .user_detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.layout_header .user_detail .name_box {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.user_detail .name_box h4,
.user_detail .name_box p {
  line-height: 1 !important;
  text-align: right !important;
}
.layout_header .users_image_box {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.layout_header .users_image_box img {
  width: auto;
  height: 40px;
  border-radius: 50%;
}

/* CONTENT */
.ant-layout-content {
  padding: 20px;
  overflow-y: auto;
}

.ant-layout-sider-trigger {
  width: 48px;
  border-radius: 8px;

  /* position: sticky; */

  /* margin-bottom: 80px; */
  padding: 5px;
  background-color: #bde556 !important;
}

/* FOOTER */
.ant-layout-footer {
  background-color: var(--footerBg);
  text-align: center;
  border-top: 1px solid var(--border);
}

.m-layout {
  display: none;
}
.m-header {
  width: 100%;
  background-color: var(--headerBg);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.m-header .header_text {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-weight: 600;
  display: flex;
  font-size: 16px;
  gap: 8px;
  opacity: 0.9;
  align-items: center;
}
.m-header .header_text img {
  width: 40px;
  opacity: 0.9;
}

.m-header .bill_icon_header {
  width: 30px;
  height: 30px;
  gap: 8px;
  opacity: 0.9;
}
.m-header .ant-btn {
  cursor: pointer;
  background: var(--mainBlue);
  color: var(--mainBlue);
  border: none;
  outline: none;
  font-size: 20px;
  width: 35px;
  height: 35px;
  box-shadow: none;
  margin-left: 8px;
  margin-top: 4px;
  padding: 0;
  border-radius: 10px !important;
}

.m-header .hamBurgerMenu_btn {
  color: black;
}

.m-header .hamBurgerMenu_btn svg {
  color: var(--white);
  opacity: 0.9;
  font-size: 30px;
}

/* DRAWER */

.mobile-menu-drawer .ant-drawer-body {
  padding: 0;
  background-color: var(--siderBg);
}

/* sider */

.unCollapsed_sider {
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important;
  height: 100vh;
}

.collapsed_sider {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
  height: 100vh;
}

/* sider */

.custom_main {
  /* background: #ffffff; */
  background-color: #f7f7f7;
  padding: 0 !important;
  height: 100vh;
}

.openbtn {
  /* background-color: #BDE556; */
  background-color: var(--primary-color) !important;
  display: flex;
  /* margin-left: 85px; */
  width: 60px;
  height: 45px;
  padding: 0px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  top: 60px;
  margin: auto !important;
}
.closebtn {
  background-color: red;
}

.btnicon {
  width: 20px;
  height: 20px;
}

.notificatioIcon-parent {
  position: relative;
}
.notificationIconTick {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  position: absolute;
  z-index: 99px;
  top: 2px;
  right: 1px;
}

.notificatioIcon-parent svg {
  font-size: 30px;
  color: "#777777";
}

@media (max-width: 900px) {
  .v-layout {
    /* display: none; */
  }
  .m-layout {
    display: block;
  }
  .ant-layout-content {
    padding: 0;
  }
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon {
  margin-left: -2px;
  margin-top: 10px;
}

.imgDiv {
  display: flex;
  height: 45px;
  width: 45px;
}

.detailBox {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 3px;
  width: 156px;

  > h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 156px;
  }

  > p {
    width: 156px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.collapsed-card {
  width: 80%;
  /* width: 0px; */
  height: 56px;
  display: flex;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-size: small;
  border-radius: 2px;
  background-color: white;
  /* padding-top: 4px; */

  border: 1px solide black;
  /* justify-content: center; */
  text-align: center;
  border-radius: 10px;
  padding: 4px;
  column-gap: 10px;
  margin-top: 6px;
}

.sideBarMenu_itemImg_parent {
  width: 25px;
}

.userCard {
  width: 248px;
  height: 56px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-size: small;
  border-radius: 2px;
  background-color: white;
  z-index: 99;
  border-radius: 10px;
  padding: 4px;
  margin-top: 6px;
  display: flex;
  gap: 10px;
  align-items: center;

  .imageBox {
    height: 45px;
    width: 45px;
  }

  .detailBox {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    flex: 1;
  }
}

._teacherDashboard {
  font-family: "Plus Jakarta Sans";
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

._teacherDashboard ._leftSection {
  width: calc(100% - 396px);
  padding-block: 15px;
}
._teacherDashboard ._leftSection ._teacherCard {
  display: none;
}
._teacherDashboard ._leftSection ._teacherCard ._singleStudentCard {
  width: 100%;
}
._teacherDashboard ._leftSection ._leftSectionWrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 40px;
}
._teacherDashboard ._rightSection {
  width: 396px;
  height: 100%;
  padding: 40px 20px;
}

._teacherDashboard ._leftSectionWrapper ._mainHeading {
  font-size: 30px;
  font-weight: 500;
}
._teacherDashboard ._leftSectionWrapper ._description {
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
  margin-top: 10px;
}
._teacherDashboard ._leftSectionWrapper ._subHeading {
  font-size: 16px;
  font-weight: 6400;
  color: black;
  margin-top: 30px;
  letter-spacing: -2%;
}

/* My Subject */
._teacherDashboard ._subjectSectionBox {
  background: #bde556;
  height: auto;
  /* max-height: auto; */
  border-radius: 15px;
  margin-top: 20px;
  padding: 10px 0 0 0;
}
._teacherDashboard ._subjectSectionBox ._subjectSectionBoxWrapper {
  background: #fafbfd;
  height: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 15px 15px 10px 15px;
}
._teacherDashboard ._subjectSectionBoxWrapper > h4 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -2%;
}
._teacherDashboard ._subjectSectionBoxWrapper > p {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-block: 15px;
}
._teacherDashboard ._subjectSectionBoxWrapper ._bottomContent {
  display: flex;
  gap: 10px;
}
._teacherDashboard ._subjectSectionBoxWrapper ._bottomContent > div {
  display: flex;
  gap: 5px;
}
._teacherDashboard ._subjectSectionBoxWrapper ._bottomContent > div > p {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

/* Classes */
._teacherDashboard ._classesSection {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 20px;
  flex-wrap: wrap;
}
._teacherDashboard ._classesSection button {
  min-width: 109px;
  border-radius: 8px;
  background-color: rgba(247, 247, 247, 1);
  color: black;
  font-size: 14px;
  font-weight: 600;
  border: none;
}
._teacherDashboard ._classesSection .activeButton {
  background-color: rgba(239, 248, 255, 1);
  color: rgba(23, 92, 211, 1);
}

/* Top Performer */
._teacherDashboard ._topPerformerHeader {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 30px;
}
._teacherDashboard ._topPerformerHeader > p {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
._teacherDashboard ._topPerformerSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

/* Upcoming Classes */
._teacherDashboard ._upcomingClassesSection {
  margin-top: 30px;
}
._teacherDashboard ._upcomingClassesSection > h2 {
  font-size: 16px;
  font-weight: 600;
}
._teacherDashboard ._upcomingClassesSection ._upcomingClassesBody {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

._noUpcomingClasses {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgba(234, 236, 240, 1);
}

.maqaasid_cards_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  margin: 10px 0px;
}

.maqaasid_cards_container .maqaasid_card {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.maqaasid_cards_container .maqaasid_card .ant-card-head {
  background-color: var(--secondary-color);
  min-height: 48px;
  padding: 0 14px;
}

.maqaasid_cards_container .maqaasid_card .ant-card-head-title {
  color: var(--primary-color);
}

.maqaasid_cards_container .maqaasid_card .ant-card-body {
  display: flex;
  align-items: center;
  padding: 15px;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.maqaasid_cards_container .maqaasid_card p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.maqaasid_cards_container .maqaasid_card .eye_icon {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.maqaasid_card:hover .eye_icon {
  background-color: rgba(0, 0, 0, 0.2);
  inset: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.maqaasid_card .eye_icon svg {
  color: var(--secondary-color) !important;
}

@media (max-width: 1318px) {
  .maqaasid_cards_container .maqaasid_card {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  ._teacherDashboard ._leftSection {
    width: 100%;
  }
  /* ._teacherDashboard ._rightSection {
    display: none;
  } */
  ._teacherDashboard ._leftSection ._teacherCard {
    display: flex;
  }
  ._teacherDashboard ._leftSectionWrapper ._description {
    display: none;
  }
  .maqaasid_cards_container .maqaasid_card {
    width: 100%;
  }
}

@media (max-width: 600px) {
  ._teacherDashboard ._leftSection ._leftSectionWrapper {
    padding: 10px 20px;
  }
  ._teacherDashboard ._leftSectionWrapper ._mainHeading {
    font-size: 22px;
  }
}

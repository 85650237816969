.container_linktree {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background-color: #f7f7f7;
  height: 100%;
  padding: 20px;

}
.top-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.greeting {
  width: 130px;
  border-radius: 4px;
  background-color: var(--secondary-color) !important;
  text-align: center;
  color: white;
  margin-bottom: 0px;
  font-size: 16px;
}
.intro-text {
  font-size: 16px;
  color: black;
  font-weight: 700;
  margin-top: 5px;
}
.social_links, .details_link{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.social_links h1{
    margin-bottom: 0px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
}
.details_link h1{
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.linkButton {
  background-color: var(--secondary-color) !important;
  color: white;
  width: 500px;
  text-transform: uppercase;
 
}
.footer{
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 5px;
}
.footer p{
  color: var(--secondary-color);
}
.footer .heart{
  font-size: 22px;
  color: var(--secondary-color);
}
@media (max-width:540px) {
 .container_linktree{
  min-width: 320px;
 }
  .linkButton{
    width: 300px;
  }
}

.maindivaddbtn{
    width: 100%;
    /* border: 1px solid black; */
}

.headingdiv{
  display: flex;
  border: 1px solid black;
}

.add_target_form{
    display: flex;
    border: 1px solid black;
    width: 100%;
}

.ant-input{
    
}
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  background-color: #eaeaea;
}

.swal-text {
  text-align: center !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: 1px;
  margin: 0;
}
.empty {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.m-20 {
  margin: 20px 0;
}

.dashboard_search {
  width: 100% !important;
  background: #fafbfd !important;
  border-radius: 15px !important;
  border: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.uploaded_image_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploaded_image_container img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.uploaded_image_container .icon_container {
  cursor: pointer;
}

.uploaded_image_container .icon_container svg {
  color: red;
}

input:focus {
  border-color: var(--secondary-color);
}

.custom_scroll_bar::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.student_custom_spinner .ant-spin-dot .ant-spin-dot-item {
  background-color: var(--secondary-color) !important;
}

.history_loading_box {
  width: 100%;
  height: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

*::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

*::-webkit-scrollbar-thumb {
  background: var(--secondary-color) !important;
  border-radius: 10px !important;
}

*::-webkit-scrollbar-track {
  background: var(--primary-color) !important;
  border: 15px;
}

._loadingBox {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.zoomBtnBox {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;

  svg {
    cursor: pointer;
  }
}
@media (max-width: 900px) {
  .zoomBtnBox {
    display: none;
  }
}

.topPerfomerContainer {
  background-color: #fff;
  height: 98vh;
  margin: 1vh 10px;
  padding: 32px 30px;
  border-radius: 10px;
}

/* .dashboard_container .left_column::-webkit-scrollbar {
  width: 8px !important; 
  height: 8px !important; 
}

.dashboard_container .left_column::-webkit-scrollbar-thumb {
  background: var(--secondary-color) !important; 
  border-radius: 10px !important; 
}

.dashboard_container .left_column::-webkit-scrollbar-track {
  background: var(--primary-color) !important;
  border:15px
}  */

.dashboard_container {
  min-height: 100vh;
  width: 100%;
}

.dashboard_container .left_column {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  /* background: #FFFFFF; */
  background: #f7f7f7;
  padding: 10px 15px;
}

.dashboard_container .left_column .wrapper {
  background: #ffffff;
  padding: 25px 25px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.dashboard_container .left_column .header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 1rem;
}

.dashboard_container .left_column .header .top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
}

.dashboard_container .left_column .header .top .title {
  color: #26252a;
  font-size: 30px;
  font-weight: 600;
}

.dashboard_container .left_column .header .top .desc {
  color: #667085;
  font-size: 16px;
  font-weight: 400;
}

.dashboard_container .left_column .header .bottom {
  width: 100%;
}

.dashboard_container .right_column {
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  background: #f7f7f7;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.dashboard_container .right_column .column_title {
  height: 5%;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: end;
}

.dashboard_container .right_column .targets_container {
  padding-top: 1rem;
  height: 95vh;
  /* background: violet; */
  overflow-y: auto;
  padding-bottom: 40px;
}

.dashboard_cards_container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.dashboard_card {
  width: 50%;
  /* background: #26252a; */
  background: var(--secondary-color) !important;
  padding: 30px 20px 20px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  position: relative;
}

.dashboard_card .book_image {
  position: absolute;
  top: -15px;
  left: -30px;
  width: 150px;
}

.dashboard_card_2 .book_image {
  position: absolute;
  top: -35px;
  left: -50px;
  width: 150px;
}

.dashboard_card .star_image {
  position: absolute;
  top: -30px;
  right: 0;
  width: 160px;
}

.dashboard_card .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_card .top .label {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.dashboard_card .top .value {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
}

.dashboard_card .card_add_btn {
  width: 100%;
  /* background: #bde556; */
  background: var(--primary-color) !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  font-size: 16px;
}

.dashboard_card .card_add_btn svg {
  width: 20px;
  height: 20px;
}

.performers_main_container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.performers_main_container .performance_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.performers_main_container .performance_header .label {
  color: #26252a;
  font-size: 16px;
  font-weight: 600;
}

.performers_main_container .cards {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.add_target_btn {
  position: absolute;
  bottom: 10px;
  /* background: #26252a; */
  background: var(--secondary-color);
  /* border: 1px solid #26252a !important; */
  border: 1px solid var(--secondary-color) !important;
  padding: 20px 0;
  border-radius: 10px;
  width: 90%;
  margin: 0 auto;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.add_target_btn:hover {
  background: #ffffff !important;
  color: #26252a !important;
}

/* dashboard collapse */
.custom_dashboard_collapse .ant-collapse-header-text {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.custom_dashboard_collapse .ant-collapse-content-box p {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.custom_dashboard_collapse .ant-collapse-header {
  flex-direction: row-reverse !important;
  border-radius: 8px;
}

/* custom collapse body */
.collapse_custom_body {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-height: 250px;
  overflow-y: auto;
}

.collapse_custom_body .collapse_custom_item {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.collapse_custom_body .collapse_custom_item .index_container {
  background: var(--primary-color);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse_custom_body .collapse_custom_item .label {
  font-size: 14px;
  font-weight: 500;
}

.view-all {
  cursor: pointer;
}

@media (max-width: 992px) {
  .dashboard_container .left_column {
    height: 100%;
    overflow: visible;
  }

  .dashboard_container .right_column {
    min-height: 100%;
    height: 100%;
    overflow: visible;
  }

  .dashboard_container .right_column .targets_container {
    height: 100%;
    overflow: visible;
  }
}

.custom_dashboard_collapse .ant-collapse-arrow {
  right: 10px;
}

@media (max-width: 768px) {
  .dashboard_cards_container {
    flex-direction: column;
    row-gap: 1rem;
  }

  .dashboard_cards_container .dashboard_card {
    width: 100%;
  }
}

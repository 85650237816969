._examsContainer {
  width: 100%;
  border-radius: 20px;
  padding: 32px 32px 32px 32px;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  /* height: calc(100% - 20px); */
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

._examsContainer ._emptyDetail {
  width: 100%;
  height: 200px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(234, 236, 240, 1);
}

._examsContainer h2 {
  font-size: 30px;
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 10px;
}
._examsContainer > p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
}

._examsContainer ._selectBoxSection {
  width: 200px;
}
._examsContainer ._selectBoxSection .ant-select {
  width: 100%;
  height: 44px;
}
.exam_text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
._examsContainer ._selectBoxSection .ant-select .ant-select-selector {
  height: 44px;
}

._examsContainer ._contentSection {
  margin-top: 30px;
}
._examsContainer ._contentSection ._awardBox {
  padding: 5px 15px;
  border-radius: 10px;

  font-size: 20px;
  font-weight: 600;

  box-shadow: 0px 0px 1px gray;
}

._examsContainer ._saveButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
._examsContainer ._saveButton button {
  width: 150px;
}

._examsContainer ._footerNextPrevButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}
._examsContainer ._footerNextPrevButton button {
  /* flex: 1; */
  height: 36px;
  border-radius: 8px;
  border: 1px solid rgba(234, 236, 240, 1);
  font-size: 12px;
  font-weight: 600;
}
._examsContainer ._footerNextPrevButton ._studentName {
  height: 36px;
  border-radius: 8px;
  background-color: var(--primary-color);
  padding-inline: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
._examsContainer ._footerNextPrevButton ._studentName > p {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  letter-spacing: -2%;
}

.exam_parent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 26px;
}

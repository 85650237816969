.Announcemnt {
  width: 100%;
  color: rgba(102, 112, 133, 1);
  background-color: #ffffff;
  height: 100%;
  font-family: Plus Jakarta Sans;
}

.announcmentTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
h2 {
  color: rgba(16, 24, 40, 1);
}
p {
  color: rgba(102, 112, 133, 1);
}
.announcmentTop h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
}
.announcmentTop p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.announcemnt .announcmentTop Button {
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: block;
  gap: 24px;
  border: 1px solid rgba(102, 112, 133, 1);
  /* min-height: 50px; */
}
.announcemnt .announcmentTop Button:hover {
  border: 1px solid rgba(102, 112, 133, 1);
}

.custom_table_design .ant-table-thead > tr > th {
  text-align: left;
}

.custom_table_design .ant-table-tbody > tr > td {
  text-align: left;
  padding: 16px 16px;
}

.create-announcement-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mark-read {
  margin-right: 8px;
}

Button:hover {
  color: rgba(102, 112, 133, 1) !important;
  border: 1px solid currentColor !important;
  outline: none !important;
}
.announcmentbottom Button {
  display: none;
}
.noannouncmentmain {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.Noannouncment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
}
.noannouncmentmain img {
  width: 100px;
  height: auto;
}
.noannouncmentmain h2 {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.noannouncmentmain p {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.noannouncmentmain .create_btn {
  width: 250px;
  gap: 20px;
  background-color: #ffffff;
}

/* create announcment */
.createAnnouncmentmain {
  margin-top: 20px;
}
.crateAnnouncment {
  padding: 30px;
  width: 100%;
  color: rgba(102, 112, 133, 1);
  background-color: #ffffff;
  min-height: 100vh;
  font-family: Plus Jakarta Sans;
}

.createAnnouncmentHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.createAnnouncmentHead h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}

.createAnnouncmentHead h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: rgba(38, 37, 42, 1);
}

.createAnnouncmentHead .buttons {
  display: flex;
  gap: 15px;
}

.createAnnouncmentHead .buttons Button:first-child {
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  /* padding: 10px 15px; */
  min-height: 40px;
  border-radius: 8px;
  border: 1px solid rgba(16, 24, 40, 0.21);
  color: rgba(38, 37, 42, 1);
}

.createAnnouncmentHead .buttons Button:last-child {
  background-color: rgba(38, 37, 42, 1);
  font-size: 14px;
  font-weight: 600;
  min-height: 40px;
  /* padding: 10px 15px; */
  border-radius: 8px;
  border: 1px solid rgba(16, 24, 40, 0.21);
  color: rgba(234, 236, 240, 1);
}

.createannouncmentFilds {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}

.createannouncmentFilds > div:first-child {
  /* flex: 0 1 auto; */
  width: 40%;
}

.createannouncmentFilds .InputField {
  border: 1px solid rgba(16, 24, 40, 0.21);
  border-radius: 8px;
  padding: 10px auto;
}

.createannouncmentFilds textarea {
  width: 100%;
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  height: 130px;
}
.createannouncmentFilds h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.createannouncmentUploadimag {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* align-items: center; */
  margin: 20px auto;
}

.createannouncmentUploadimag > div:first-child {
  width: 25%;
}

.createannouncmentUploadimag .Dragger {
  width: 100%;
}
.createannouncmentUploadimag h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
}
.createannouncmentUploadimag .email {
  width: 40% !important;
}
.createannouncmentUploadimag .custom_dragger .ant-upload-drag {
  border: 2px dashed rgba(16, 24, 40, 0.21);
  width: 100%;
}

.createannouncmentUploadimag .custom_dragger .ant-upload-drag:hover {
  border: 2px dashed rgba(16, 24, 40, 0.21) !important;
}

.createannouncmentUploadimag .custom_dragger .uploadicon {
  padding: 10px;
  border: 1px solid rgba(16, 24, 40, 0.21);
  border-radius: 8px;
  font-size: 45px;
}

/* RecentAnnouncment styling */
.RecentAnnouncmentMain {
  margin-top: 20px;
}
.RecentAnnouncmentMain > h2 {
  font-weight: 600;
  font-size: 16px;
  margin: 30px auto;
  line-height: 24px;
}
.RecentAnnouncmentMain h2 {
  font-weight: 600;
  font-size: 16px;
  /* margin: 10px auto; */
  line-height: 24px;
}

.announcmentcard {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(232, 235, 241, 1);
  height: 60px;
  overflow: hidden;
  margin: 10px auto;
}
.cardHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardHeading h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.cardHeading .time {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.announcmentcard p {
  padding: 20px auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #26252a;
}

.noAnnouncmentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.noAnnouncmentContainer div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noAnnouncmentContainer div > div:nth-of-type(2) {
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.noAnnouncmentContainer div > div:nth-of-type(2) > h2 {
  font-size: 16px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}

.noAnnouncmentContainer div > div:nth-of-type(2) > p {
  font-size: 14px;
  font-weight: 400;
  color: #667085;
  font-family: "Plus Jakarta Sans";
}

.table_data_text {
  margin-left: 13px;
}

.table_data_text > p {
  font-size: 14px;
}

.table_users_tag {
  width: 30px;
  padding: 5px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  background-color: black;
  color: white;
}

.table_users_tooltip_tag {
  background-color: #bde55680;
  margin: 5px;
}

.table_actions {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.table_actions svg {
  width: 20px;
  height: 20px;
  margin: 10px 0px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .Announcemnt {
    padding: 10px;
  }
  .createannouncmentFilds {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    margin: 20px auto;
    gap: 12px;
  }
  .createannouncmentUploadimag {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* align-items: center; */
    margin: 20px auto;
    gap: 12px;
  }
}

@media screen and (max-width: 500px) {
  .Announcemnt {
    padding: 10px;
  }
  .announcmentcard {
    padding: 5px;
    height: 80px;
  }
  .crateAnnouncment {
    padding: 10px;
  }
  .announcmentTop Button {
    display: none;
  }
  .announcmentbottom Button {
    display: block;
  }

  .cardHeading h2 {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
  }
  .announcmentcard p {
    text-align: center;
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;
  }
  .cardHeading .time {
    /* display: none; */
    font-size: 10px;
  }

  .createAnnouncmentHead .buttons {
    display: none;
    gap: 15px;
  }

  .bottombtton .buttonsmobile {
    display: block;
  }

  .bottombtton .buttonsmobile Button:first-child {
    background-color: rgba(38, 37, 42, 1);
    font-size: 14px;
    font-weight: 600;
    min-height: 45px;
    width: 100%;
    margin: 10px auto;
    border-radius: 8px;
    border: 1px solid rgba(16, 24, 40, 0.21);
    color: rgba(234, 236, 240, 1);
  }
  .bottombtton .buttonsmobile Button:last-child {
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    min-height: 45px;
    border-radius: 8px;
    margin: 10px auto;
    border: 1px solid rgba(16, 24, 40, 0.21);
    color: rgba(38, 37, 42, 1);
  }

  .bottombtton .buttonsmobile Button:hover {
    border: 1px solid rgba(16, 24, 40, 0.21) !important;
  }
}

/* media query */

/* for 634px */

@media screen and (max-width: 634px) {
  .announcmentcard {
    height: auto;
  }

  .cardHeading {
    display: block;
    text-align: center;
  }

  .notification-title {
    /* text-align: center; */
  }

  .cardHeading h2 {
    text-align: center;
  }
}

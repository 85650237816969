.signupBtn {
  cursor: pointer;
}

.mobileMenuIcon {
  color: #bde556 !important;
}

.mobileMenuBar {
  width: 100%;
}

.ant-menu-submenu-arrow {
  display: none;
}

.mobileLabelClass {
  margin-left: 15px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--secondary-color) !important;
  background-color: var(--secondary-color) !important;
}

.ant-radio-input:hover {
  border-color: var(--secondary-color) !important;
}

.ant-radio:hover {
  border-color: var(--secondary-color) !important;
}

.ant-radio-wrapper .ant-radio-checked::after {
  border-color: var(--secondary-color) !important;
}

.ant-select-selector,
.ant-input:hover {
  border-color: var(--secondary-color) !important;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio:hover .ant-radio-inner {
  border-color: var(--secondary-color) !important;
}

.ant-select-selector .ant-form-item-explain .ant-form-item-explain-error {
  margin-top: 20px !important;
}

.ant-checkbox:hover .ant-checkbox-inner {
  border: 1px solid var(--secondary-color) !important;
}
.ant-checkbox .ant-checkbox-inner {
  border-color: #d0d5dd !important;
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border: 1px solid #d0d5dd !important;
}

/* .ant-input-affix-wrapper{
  background-color: var(--secondary-color) !important;
} */

.loading-parent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.changeForResponsive {
  width: 230px;
}

@media screen and (max-width: 980px) {
  .Custom_tabs_container {
    display: flex;
    width: fit-content;
  }

  .Custom_active_tab {
    width: 100% !important;
  }

  .tabs {
    text-align: center;
  }
}

/* for 899px */

@media screen and (max-width: 899px) {
  .v-layout {
    display: none !important;
  }

  .table_container {
    overflow-x: scroll !important;
  }

  /* .teachers_container .table_container{
    overflow-x: auto !important ;
  } */

  .ant-table-cell {
    width: 200px !important;
  }

  .random-text {
    text-align: center !important;
  }

  .assesment-heading {
    text-align: center;
  }

  .create-exam-btn {
    right: 0 !important;
  }

  .hideOnMobileScreen {
    display: none;
  }
}

/* for 768px */

/* @media screen and (max-width:768px) {
  .custom_card{
    display: block !important;
    width: 100%;
  }
} */

/* for > 576px */

@media screen and (min-width: 576px) {
  .responsiveLogo {
    display: none;
  }
}

/* for < 576px */

@media screen and (max-width: 576px) {
  .changeForResponsive {
    display: none;
  }

  .newLogoDiv {
    display: none;
  }

  .responsiveText {
    font-size: 24px !important;
    width: 160px !important;
  }

  .header {
    display: flex;
  }

  .responsiveLogo {
    width: 50px;
    height: 50px;
    /* position: relative; */
    /* left: 15px; */
    margin-top: 10px;
    margin-left: 20px;
  }

  .right_column {
    width: 100% !important;
  }

  .student_performance_card,
  .teacher_performance_card {
    overflow-x: scroll;
  }

  .additionalClassStudent {
    width: auto;
  }

  .additionalClass {
    width: 70px;
    text-align: center;
  }

  .additionalStudentClass {
    width: 90px;
  }

  .title {
    font-size: 26px !important;
    line-height: 30px;
    text-align: center;
  }

  .desc {
    text-align: center;
  }

  .additionalValueClass {
    font-size: 22px !important;
  }

  .additionalTeacherClass {
    width: 130px;
  }

  .resposniveClasses {
    display: block;
  }

  .xsJustifyCenter {
    display: block;
    width: 100%;
    text-align: center !important;
  }

  .componentHeading {
    font-size: 26px;
    width: 100%;
    text-align: center;
  }

  .componentPeople {
    width: 80px;
    display: block;
    text-align: center;
    margin: 10px auto !important;
  }

  .componentText {
    text-align: center;
  }

  .componentBtnText {
    display: block;
    margin: 0 auto;
    position: relative !important;
  }

  .componentBtnText {
    margin-bottom: 10px;
  }

  .Custom_tabs_container {
    display: block !important;
    width: auto;
    text-align: center;
  }

  /* .ant-col{
  margin: 0 auto !important;
  width:100%;
} */

  .select_container {
    display: block;
    width: 100%;
  }

  .fliters_container {
    display: block;
  }

  .select_container {
    margin-top: 10px;
    width: 100% !important;
  }
}
.ant-btn-primary {
  background-color: var(--secondary-color);
}
.ant-btn-primary:hover {
  background-color: var(--secondary-color) !important;
}

/* for 415 to 430 */

@media screen and (min-width: 415px) and (max-width: 430px) {
  /* .left{
    overflow-y: clip !important;
  } */

  /* .main{
    overflow-x: clip !important;
  } */

  .signupheading {
    /* left: 20px; */
  }

  .pl {
    margin-bottom: 30px;
  }

  .signupform {
    /* height: 80%; */
  }
}

/* for 430px */

@media screen and (max-width: 430px) {
  /* login css start */

  .loginForm {
    width: 100%;
    /* height: 70%; */
  }

  .mobile-login {
    /* margin-top: 90px; */
  }

  .input {
    width: 100%;
  }

  /* login css end */

  /* signup page css start */
  .left {
    /* overflow-y: clip !important; */
    width: 100%;
  }

  .signupform {
    /* position: fixed; */
    /* left: 0 !important; */
    width: 90%;
    /* margin-left: 20px; */
  }

  .responsive-details {
    /* margin-top: 10px; */
    margin-bottom: 40px;
    position: relative;
    right: 80px;
  }

  .screenimg {
    display: none;
  }

  /* .pl{
      margin-left: 10px;
    } */

  .signup-btn {
    width: 314px !important;
  }

  /* .signupform{
      height: 70%;
    } */

  .responsive-signup {
    margin-bottom: 20px;
    margin-top: 100px;
    font-size: 24px;
  }

  /* .responsive-signup{
      margin-top: 40px;
    } */

  /* signup page css end */

  .create-exam-btn {
    right: 0 !important;
  }
}

/* for 400px */

@media screen and (max-width: 414px) {
  /* login page css start */

  .screenimg {
    display: none;
  }

  .change-for-responsive {
    display: none;
  }

  .mobile-login {
    /* position: absolute !important; */
    /* left: 40px !important; */
    margin-bottom: 0;
    font-size: 24px !important;
  }

  /* 
    .signupform{
      top: 0 !important;

  } */

  /* .left{
      overflow-y: unset !important;
    } */

  /* .main{
      overflow-x: clip !important;
    } */

  /* .pl{
      position: relative !important;
      left: 0 !important;
    } */

  .input {
    width: 99% !important;
    margin-left: 0;
  }

  .button {
    width: 345px !important;
    margin-left: 0;
  }

  /* login page css end */

  /* signup page css start */

  .responsive-signup {
    margin-bottom: 10px;
    font-size: 24px;
    margin-top: 40px;
  }

  .responsive-details {
    color: #667085;
    text-align: left !important;
    position: relative !important;
    right: 70px !important;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .signupBtn {
    width: 314px !important;
  }

  /* signup page css end */
}

/* for 390 px and 414px */

@media screen and (min-width: 393px) and (max-width: 428px) {
  /* .mobile-login{
      left: 20px !important;
    } */

  /* .left{
      overflow-y: unset !important;
    }

    .main{
      overflow-x: clip !important;
    } */

  .mobile-login {
    /* position: relative !important; */
    /* left: 40px !important; */
    font-size: 24px !important;
    margin-bottom: 10px;
  }

  .pl {
    /* position: relative;
      left: -8px; */
    margin-bottom: 10px;
  }
}

/* for 390px */

@media screen and (max-width: 390px) {
  /* login page css start */
  .mobile-login {
    /* position: relative !important; */
    /* left: 5px !important; */
    margin-bottom: 0;
    font-size: 24px !important;
  }

  /* login page css end */

  /* sign up page css start */

  .responsive-details {
    right: 70px !important;
    margin-bottom: 15px;
  }

  .responsive-signup {
    margin-top: 100px;
  }

  .signupBtn {
    width: 340px !important;
  }

  .signupform {
    height: 90%;
  }

  /* sign up page css end */
}

/* for 360px */

@media screen and (max-width: 360px) {
  /* login page css start */
  /* .mobile-login{
      left: 0px !important;
    } */

  /* .pl{
     margin-left: 10px;
    } */

  /* login page css end */

  /* sign up page css start */

  .responsive-details {
    right: 70px !important;
  }

  /* sign up page css end */
}

/* for 320 and 360px */

@media screen and (min-width: 320px) and (max-width: 360px) {
  /* login css start */

  .input {
    width: 100% !important;
    margin: 0 auto !important;
  }

  /* .responsive-login-form{
          margin-left: 30px;
        } */

  .signupform {
    /* margin-left: 15px; */
  }

  .mobile-login {
    /* position: relative !important;
          right: 20px; */
    width: 95% !important;
  }

  .pl {
    /* position: relative;
          right: 20px; */
    font-size: 17px;
    /* margin:10px 0 10px 5px; */
    width: 100%;
  }

  .responsive-login-btn {
    width: 323px !important;
    position: relative;
    right: 2px;
  }

  /* login css end */

  /* signup css start */

  .responsive-signup {
    margin-bottom: 20px;
    margin-top: 180px;
  }

  .left {
    height: 100%;
  }

  .responsive-details {
    right: 19% !important;
  }

  .responsive-form {
    width: 99%;
  }

  .responsive-signup-btn {
    width: 293px !important;
  }

  .signupBtn {
    width: 280px !important;
  }

  .signupform {
    height: 80%;
  }

  /* signup css end */
}

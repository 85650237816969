.logout_modal {
  background: #ffffff !important;
  border-radius: 8px ;
}
.ant-modal-footer {
  display: flex;
  align-items: center !important;
  gap: 10px !important;
}
.logoutheading {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin-top: 20px;
}
.logout_modal  .ant-btn {
  width: 50% !important;
}
.logout_modal p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
.logout_modal  .ant-btn:last-child {
  background-color: #D92D20 !important;
  height: 35px !important;
}
.logout_modal .logout_icons {
  padding: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  height: auto;
  border-radius: 50%;
  font-size: 25px;
  background-color: #fee4e2;
}

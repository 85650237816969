.file_manager_header {
  width: 100%;
}

.fm_upload_btn .ant-upload-select {
  width: 100% !important;
  height: 100% !important;
}

.fm_upload_btn {
  border: none;
}
.fm_upload_btn button {
  width: 100% !important;
  height: 100% !important;
  border-radius: 6px;
  text-align: left;
  padding: 10px 20px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.fm_upload_btn button:hover {
  color: #000 !important;
  cursor: pointer;
}

.file_modal_upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file_modal_upload button:hover {
  border: 0 !important;
  cursor: pointer;
}

.file_manager_modal .modal_footer {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.file_manager_modal .modal_footer .modal_footer_btn {
  width: fit-content;
}

.file_grid {
  gap: 1rem;
  padding: 1rem 0;
}

.file_box {
  background-color: #f7f7f7;
  padding: 10px 15px;
  border-radius: 6px;
}

.file_box .file_box_header {
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.file_box .file_box_header p {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #000;
}

.file_box .file_box_body {
  /* padding: 10px; */
  background-color: #fff;
  height: 130px;
  text-align: center;
  align-content: center;
  position: relative;

  .svgIcon {
    font-size: 50px;
  }
}
.file_box .file_box_body .previewBox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  svg,
  p {
    color: #fff;
  }
}
.file_box .file_box_body .previewBox:hover {
  opacity: 1;
}

.file_box .file_box_footer {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file_box .file_box_footer .side_options {
  cursor: pointer;
}

.side_options_popover {
  padding: 0px;
  width: 200px !important;
}

.side_options_popover .side_options_popover_option {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  padding: 10px 5px;
  font-size: 18px;
  text-transform: capitalize;
  align-items: center;
  color: #000;
}

.side_options_popover .delete_clr {
  color: red;
}

.side_options_popover .side_options_popover_option:hover {
  background-color: #d0d5dd;
}

/* PREVIEW MODAL */
.previewModal {
}
.previewModal .videoPreview,
.previewModal .imagePreview {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.previewModal .videoPreview video,
.previewModal .imagePreview img {
  max-width: 100%;
  height: auto;
}

.previewModal #msdoc-renderer {
  height: 80vh;
}




._studentDashboard {
  font-family: "Plus Jakarta Sans";
  width: 100%;
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

._studentDashboard ._leftSection {
  width: calc(100% - 300px);
  padding-block: 15px;
}
._studentDashboard ._leftSection ._studentCard {
  display: none;
}
._studentDashboard ._leftSection ._studentCard ._singleStudentCard {
  width: 100%;
}
._studentDashboard ._leftSection ._leftSectionWrapper {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 40px;
}
._studentDashboard ._rightSection {
  width: 300px;
  height: 100%;
  padding: 40px 20px;
}

._studentDashboard ._leftSectionWrapper ._mainHeading {
  font-size: 30px;
  font-weight: 500;
}

._studentDashboard ._leftSectionWrapper ._description {
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
  margin-top: 10px;
}

/* CHART */
._studentDashboard ._chartSection {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

._studentDashboard ._chartSection ._chartBox {
  flex: 1;
}

._studentDashboard ._chartSection ._chartBox ._chartHeading {
  font-weight: 600;
  font-size: 16px;
  color: black;
}

._studentDashboard ._chartSection ._chartBox ._chart {
  border: 1px solid rgb(234, 236, 240);
  border-radius: 12px;
  margin-top: 10px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
._chart .gauge-chart .needle-base {
  fill: white; /* White background */
  stroke: black; /* Black border */
  stroke-width: 2; /* Thickness of the border */
}

/* Latest Exam Result */
._studentDashboard ._latestExamResult {
  border: 1px solid rgb(234, 236, 240);
  border-radius: 12px;
  padding: 16px;
  margin-top: 30px;
}

._studentDashboard ._latestExamResult ._resultHeader {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}


._studentDashboard ._latestExamResult ._resultHeader > h2 {
  font-weight: 600;
  font-size: 16px;
  color: black;
  letter-spacing: -2%;
}

._studentDashboard ._latestExamResult ._resultHeader > div {
  display: flex;
  gap: 10px;
  align-items: center;
}
._studentDashboard ._latestExamResult ._resultHeader > div > p {
  font-weight: 400;
  font-size: 16px;
}

._studentDashboard ._latestExamResult ._resultBody {
  width: 100%;
  margin-top: 20px;
}

/* Latest Achieved Targets */
._studentDashboard ._latestAchievedTargets {
  border: 1px solid rgb(234, 236, 240);
  border-radius: 12px;
  padding: 16px;
  margin-top: 30px;
}

._studentDashboard ._latestAchievedTargets ._targetHeader {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

._studentDashboard ._latestAchievedTargets ._targetHeader > h2 {
  font-weight: 600;
  font-size: 16px;
  color: black;
  letter-spacing: -2%;
}

._studentDashboard ._latestAchievedTargets ._targetBody {
  margin-top: 10px;
}

/* Right Section */
._studentDashboard ._studentDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

._underperformance,
._overperformance {
  font-size: 14px;
  font-weight: 500;
  padding: 2px 5px 4px 5px;
  border-radius: 30px;
  line-height: 1;
}
._underperformance {
  color: rgba(217, 45, 32, 1);
  border: 1px solid rgba(217, 45, 32, 1);
  background-color: rgba(254, 243, 242, 1);
}
._overperformance {
  color: rgba(196, 50, 10, 1);
  border: 1px solid rgba(196, 50, 10, 1);
  background-color: rgba(255, 246, 237, 1);
}

._studentDashboard ._studentDetails > h2 {
  font-size: 16px;
  font-weight: 600;
}

._studentDashboard ._studentDetails ._studentNameBox {
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  padding-inline: 10px;
}
._studentDashboard ._studentDetails ._studentNameBox > h3 {
  font-size: 18px;
  font-weight: 600;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
._studentDashboard ._studentDetails > p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -2%;
  margin-top: 10px;
}

._studentDashboard ._studentDetails ._studentImage {
  width: 170px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

._studentDashboard ._studentDetails ._studentImage img {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
}
._studentDashboard ._studentDetails ._classAndContact {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
._studentDashboard ._studentDetails ._classAndContact p {
  font-size: 16px;
  font-weight: 400;
}

._studentDashboard ._classesProgressSection {
  margin-top: 30px;
}
._studentDashboard ._classesProgressSection h2 {
  font-size: 16px;
  font-weight: 600;
}

._studentDashboard ._classesProgressBox {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 1250px) {
  ._studentDashboard ._chartSection {
    flex-direction: column;
  }
}

@media (max-width: 1100px) {
  ._studentDashboard ._leftSection {
    width: 100%;
  }
  /* ._studentDashboard ._rightSection {
    display: none;
  } */
  ._studentDashboard ._leftSection ._studentCard {
    display: flex;
  }
  ._studentDashboard ._leftSectionWrapper ._description {
    display: none;
  }
}

@media (max-width: 600px) {
  ._studentDashboard ._leftSection ._leftSectionWrapper {
    padding: 10px 20px;
  }
  ._studentDashboard ._leftSectionWrapper ._mainHeading {
    font-size: 22px;
  }
  ._studentDashboard ._latestExamResult,
  ._studentDashboard ._latestAchievedTargets {
    border: none;
    padding: 0px;
  }
}

._lessonDetailBox ._isLoading {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
._lessonDetailBox ._emptyData {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
._lessonDetailBox {
  padding: 32px 32px 32px 32px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 20px;
  width: 100%;
  height: calc(100vh - 20px);
  overflow-y: auto;
}

._lessonDetailBox ._breadcrumb {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-bottom: 30px;
}
._lessonDetailBox ._breadcrumb span {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
._lessonDetailBox ._breadcrumb p {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: var(--secondary-color);
  padding: 0px 10px;
  border-radius: 6px;
}

._lessonDetailBox > h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}
._lessonDetailBox > P {
  font-size: 16px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
  margin-bottom: 30px;
}
._lessonDetailBox > h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

._lessonDetailBox ._durationSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

._lessonDetailBox ._durationBox {
  padding: 6px 10px;
  width: max-content;
  display: flex;
  justify-content: center;
  color: var(--secondary-color);
  border-radius: 8px;
  background-color: #eff8ff;
  align-items: center;
  gap: 3px;
}
._lessonDetailBox ._durationBox p {
  font-size: 14px;
  font-weight: 600;
  color: blue;
}

._lessonDetailBox .ant-collapse {
  border: none !important;
  margin: 10px 0px !important;
}
._lessonDetailBox .ant-collapse-content {
  border-bottom: none;
  border-top: none;
}
._lessonDetailBox .ant-collapse-item {
  border-bottom: none;
}

._lessonDetailBox .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  right: 10px;
}

._lessonDetailBox .collaps_1 .ant-collapse-header {
  background: #fafbfd !important;
  padding: 15px 10px !important;
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  align-items: center;
}

._lessonDetailBox .ant-collapse-content {
  background-color: #fafbfd;
}

._lessonDetailBox .ant-collapse-content-box {
  padding: 0px !important;
}

._lessonDetailBox .ant-collapse-header-text {
  padding: 0px !important;
  font-weight: 600;
  font-size: 14px;
}
._lessonDetailBox .list {
  padding: 0px 20px 20px 20px;
}
._lessonDetailBox .list .list_item {
  display: flex;
  align-items: center;
  justify-content: start;
}
._lessonDetailBox .list .list_item > p {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: black;
  margin-left: 10px;
}
._lessonDetailBox .list .list_item > div {
  width: 6px;
  height: 6px;
  background: #175cd3;
  border-radius: 50%;
}

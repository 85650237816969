.add-classes-logo{
    width: 40px;
    position: absolute;
    top:15px;
}

.adjust-alignment{
    line-height: 15px;
    margin-bottom: 15px;
}

.add-class-styling{
    margin-top: 30px;
    margin-bottom: 0;
}

.fill-out-styling{
    margin-top: 40px;
    
}

.teachers-container{
    /* height: 110vh; */
}

/* styles.css */
.classes-scrollbar {
    height: calc(100vh - 230px); /* Adjust this as necessary */
    overflow-y: scroll;
  }
  
  .classes-scrollbar::-webkit-scrollbar {
    width: 10px;
  }
  
  .custom_table_design{
    height: auto;
    min-width: 880px;
    /* height: 200px; */
  }

 

  .custom-tooltip .ant-tooltip-arrow {
    display: none;
  }

  /* Custom table cell styles */
.custom_table_design .ant-table-cell {
  padding: 4px 8px; /* Adjust the padding as needed */
  font-size: 12px; /* Adjust the font size as needed */
}

/* Custom table row styles */
/* .custom_table_design .ant-table-tbody > tr > td {
  height: 36px; 
} */

/* Custom table header styles */
/* .custom_table_design .ant-table-thead > tr > th {
  padding: 4px 8px; 
  font-size: 12px; 
} */

.ant-table-wrapper .ant-table-tbody>tr>td{
  padding: 3px;
  /* text-align: center; */
}

.delete_btn_disabled{
  background: transparent;
  border: none;
}

.delete_btn_disabled:hover{
  border: none !important;
  outline: none;
}

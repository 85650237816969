._scheduleContainer {
  width: 100%;
  border-radius: 20px;
  padding: 32px 0px 32px 0px;
  border: 1px solid rgb(214, 213, 213);
  background-color: #fff;
  height: 100vh;
  overflow-y: auto;
}

._scheduleContainer ._scheduleHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 30px;
}

._scheduleContainer ._scheduleHeader ._left h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: rgba(38, 37, 42, 1);
}
._scheduleContainer ._scheduleHeader ._left p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(102, 112, 133, 1);
}

._scheduleContainer ._scheduleHeader ._right {
  display: flex;
  gap: 20px;
}
._scheduleContainer ._scheduleHeader ._right > button {
  width: 138px;
  height: 40px;
  border-radius: 8px;
  /* background-color: rgba(38, 37, 42, 1) !important; */
  background-color: var(--secondary-color) !important;
  color: #fff !important;
}
._scheduleContainer ._scheduleHeader ._right > button:hover {
  color: #fff !important;
}
._scheduleContainer ._scheduleHeader ._right .ant-select {
  width: 200px !important;
  height: 40px;
}
._scheduleContainer ._scheduleHeader ._right .ant-select :hover {
  border-color: rgb(163, 163, 163) !important;
}
._scheduleContainer ._scheduleHeader ._right .ant-select-selector {
  height: 40px;
  border-radius: 8px;
}

._scheduleContainer ._scheduleBoxWrapper {
  width: 100%;
  margin-top: 30px;
  padding-inline: 10px;
}
._scheduleContainer ._scheduleBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
._scheduleContainer ._scheduleBox div:last-child div:last-child {
  border-bottom-right-radius: 10px;
}
._scheduleContainer ._scheduleBox div:last-child div:nth-child(2) {
  border-bottom-left-radius: 10px;
  border-left: 1px solid rgba(236, 238, 245, 1);
}
._scheduleContainer ._scheduleBox div:last-child div:nth-child(1) {
  border-right: none;
}

._scheduleContainer ._scheduleBox ._headerRow {
  width: 100%;
  display: flex;
  flex-direction: row;
}
._scheduleContainer ._scheduleBox ._headerRow div:nth-child(2) {
  border-top-left-radius: 10px;
  border-left: 1px solid rgba(236, 238, 245, 1);
}
._scheduleContainer ._scheduleBox ._headerRow div:last-child {
  border-top-right-radius: 10px;
}

._scheduleContainer ._scheduleBox ._headerChildFirst {
  width: 65px;
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
}
._scheduleContainer ._scheduleBox ._headerChildOthers {
  width: calc((100% - 65px) / 7);
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 251, 253, 1);
  border-top: 1px solid rgba(236, 238, 245, 1);
  border-bottom: 1px solid rgba(236, 238, 245, 1);
  border-right: 1px solid rgba(236, 238, 245, 1);
}
._scheduleContainer ._scheduleBox ._headerChildOthers > p {
  text-transform: uppercase;
  color: #000;
}

._scheduleContainer ._scheduleBox ._dataRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 72px;
  /* align-items: center; */
}

._scheduleContainer ._scheduleBox ._dataChildFirst {
  width: 65px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(236, 238, 245, 1);
}
._scheduleContainer ._scheduleBox ._dataChildOthers {
  width: calc((100% - 65px) / 7);
  height: inherit;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(236, 238, 245, 1);
  border-right: 1px solid rgba(236, 238, 245, 1);
  z-index: 1;
}
._scheduleContainer ._scheduleBox ._dataChildOthers > div:not(:first-child) {
  margin-top: -1px;
}

/* SCHEDULE CARD */
._scheduleCard {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding: 2px 10px 2px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.4s ease-out;
  position: relative;
}

._scheduleCard:hover:before {
  opacity: 1;
}

._scheduleCard:hover ._info {
  opacity: 1;
  transform: translateY(0px);
}

._scheduleCard:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

._scheduleCard ._info {
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

._scheduleCard ._scheduleCardTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
}
._scheduleCard ._scheduleCardTop p {
  font-weight: 400;
  font-size: 9px;
  line-height: 1;
  margin: 0;
}
._scheduleCard ._scheduleCardBottom {
  display: flex;
  flex-direction: row;
}
._scheduleCard ._scheduleCardBottom p {
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  margin: 0;
}

._scheduleCardForMobile {
  position: relative;
}
._scheduleCardForMobile ._scheduleIcon {
  position: absolute;
  right: 5px;
  height: 100%;
  width: auto;
}

/* SCHEDULE MOBILE SCREEN */
._scheduleContainerMobile {
  width: 100%;
}
._scheduleContainerMobile > h2 {
  font-size: 22px;
  font-weight: 500;
}

._scheduleContainerMobile ._scheduleBoxMobile {
  border-radius: 10px;
  border: 1px solid rgba(236, 238, 245, 1);
  margin-top: 30px;
}

._scheduleContainerMobile ._scheduleBoxMobile ._scheduleBoxMobileHeader {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid rgba(236, 238, 245, 1);
  background-color: rgba(250, 251, 253, 1);
  display: flex;
  flex-direction: row;
  padding-inline: 20px;
  align-items: center;
  height: 52px;
}
._scheduleContainerMobile ._scheduleBoxMobile ._scheduleBoxMobileHeader > h2 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15.12px;
  letter-spacing: -2%;
  text-align: center;
  text-transform: uppercase;
  flex: 1;
}
._scheduleContainerMobile ._scheduleBoxMobile ._scheduleBoxMobileHeader svg {
  color: rgba(102, 112, 133, 1);
  cursor: pointer;
}

._scheduleContainerMobile ._scheduleBoxMobileRow {
  display: flex;
  min-height: 60px;
}
._scheduleContainerMobile ._scheduleBoxMobileRow ._time {
  width: 60px;
  border-right: 1px solid rgba(236, 238, 245, 1);
  height: inherit;
  display: flex;
  align-items: center;
  padding-inline: 7px;
  position: relative;
}

._scheduleContainerMobile ._scheduleBoxMobileRow ._time svg {
  position: absolute;
  right: -8px;
  box-shadow: 0px 0px 0px 3px rgba(23, 92, 211, 0.1);
  border-radius: 50%;
}
._scheduleContainerMobile ._scheduleBoxMobileRow ._schedule {
  width: calc(100% - 60px);
  height: inherit;
  padding: 20px 0px 20px 40px;
  display: flex;
  flex-direction: column;
}
._scheduleContainerMobile
  ._scheduleBoxMobileRow
  ._schedule
  > div:not(:first-child) {
  margin-top: -1px;
}

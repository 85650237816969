/* .signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 45%;
  margin: auto;
} */
/* .signin .login-form {
  height: auto;
  margin: 20px 0;
   border: 1px #ddd solid; 
  box-shadow: var(--boxShadow);
  background-color: white;
  padding: 20px;
  border-radius: 2px;
  width: 100%;
}
.signin .login-form img {
  margin: 0 auto;
  display: flex;
}

.signin .login-form h2 {
  font-weight: bold;
  text-align: center;
  color: black;
}
.signin .login-form button {
  width: 100%;
  height: 45px;
  border: none;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: var(--primary-color-local);
} */
/* .signin .for-signup {
  text-align: center;
  width: 100%;
}
.signin .for-signup button {
  width: 100%;
  height: 40px;
  margin: 10px auto;
  border-color: #adb1b8 #a2a6ac #8d9096;
  border: 1px solid;
  background: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
}

.signin .for-signup .signin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47%;
}
.signin .for-signup .signin-btn svg {
  margin-right: 10px;
  font-size: 20px;
}
.signin .for-signup .signin-gf {
  display: flex;
} */

/* forget password  */
/* .signin-forget {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
}
.signin-forget .forget-inner {
  width: 40%;
  height: 200px;
}
.signin-forget img,
.signin-forget h4,
.signup img,
.signup h4 {
  margin-bottom: 20px;
}
.for-signup h4 {
  margin: 10px 0;
}

.signin-forget p {
  margin-top: 20px;
  letter-spacing: 1px;
}
.signin-forget button {
  width: 100px;
  display: block;
  margin: 20px auto 0 auto;
} */

/* signup */

/* .signup {
  margin: 30px auto;
} */

/* media queries */

/* @media (max-width: 770px) {
  .signin,
  .signin-forget .forget-inner,
  .signup {
    width: 60%;
  }
}

@media (max-width: 550px) {
  .signin,
  .signup,
  .signin-forget .forget-inner {
    width: 90%;
  }
} */



/* src/index.css or the appropriate CSS file */





@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

.main {
  width: 100%;
  height: 100vh;
  overflow-x: clip;
}

.signupform {
  color: #667085;
  /* position: relative; */
  /* left: 140px; */
  /* top: 10%; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 80%;
  /* width: 360px; */
  /* height:552px ; */
}



.left {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* height: 100vh; */
  background-color: white;

}

.right {
  height: 100vh;
  background-color: #F2F4F7;
  position: relative;
  display: flex;
}

h1 {

  font-weight: 600;
  font-style: 36px;
  line-height: normal;
  text-align: left;
  width: 100%;
  margin: 0 0 20px 0;
}

.button {
  border-radius: 8px;
  height: 44px;
  width: 360px;
  background-color: #344054;
  border: none;

}

.input {
  width: 360px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  padding-left: 12px;
}
.input:hover{
  border: 1px solid var(--secondary-color);
}


.loginInput .ant-input-affix-wrapper{
  background-color: var(--secondary-color) !important;
}
.innerdiv {
  position: absolute;
  top: 50%;
  right: 0;
  left: 400px;
  transform: translate(-50%, -50%);
  height: 450px;
  width: 600px;
  background-color: white;
  border-radius: 12px;
}

.loginForm {
  /* color: #667085;   
    position: relative;
    left: 10px; */
  /* top: 35px;
    display: flex;
    flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 95%;
    height: 90vh; */

  color: #667085;
  /* position: relative; */
  /* left: 140px; */
  /* top: 10%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width:360px; */




}

.responsive-login-form {
  width: 100%;
}




.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--secondary-color) !important;
  /* border-color: red !important; */
}

.ant-input-affix-wrapper:focus-within{
  border-color: var(--secondary-color) !important ;
}
.ant-input-affix-wrapper:hover{
  border-color: var(--secondary-color) !important ;
}


/* for 400px */

/* @media screen and (max-width:400px) {
    
    .screenimg{
      display: none;
    }

    .change-for-responsive{
      display: none;
    }

    .mobile-login{
      position: relative !important;
      left: 0px !important;
      margin-bottom: 0;
      font-size: 24px !important;
    }

    .signupform{
      top: 0 !important;

  }

    .left{
      overflow-y: unset !important;
    }

    .main{
      overflow-x: clip !important;
    }

    .pl{
      position: relative !important;
      left: 0 !important;
    }

    .input{
      width: 340px !important;
      margin-left: 0;
    }

    .button{
      width: 345px !important;
      margin-left:0;
    }

  } */
.generate_text {
    font-size: 30px;
    font-weight: 500;
    font-family: "Plus Jakarta Sans";
    color: #101828;

}

.generate_main {
    padding: 30px 0;
}

.generate_main {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.generator_text {
    font-weight: 600;
    font-size: 18px;
    color: #101828;
}

.generateCard_main {
    padding: 15px;
    margin-top: 25px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
}

.generateCard_numb_parent {
    display: flex;
    align-items: center;
    gap: 5px;
}

.generateCard_numb {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    cursor: pointer;
}

.generateCard_text {
    color: #101828;
    font-size: 14px;
    font-weight: 500;
    font-family: "Plus Jakarta Sans";
}

.std_reports_text_head {
    background-color: var(--secondary-color);
    color: var(--primary-color) !important;
    padding: 8px;
    border-radius: 6px;
}

.generateCard_numb_active {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.generate_btn_parent {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}

.generate_btn_cancel {
    width: 50%;
    background-color: transparent;
    color: black;
}

.generate_btn_submit {
    width: 50%;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.custom-spin .ant-spin-dot i {
    background-color: white;
}


.reportSpin .anticon svg{
    fill: #ffffff !important;
}

@media only screen and (max-width: 450px) {
    .generate_btn_parent {
        flex-direction: column;
    }

    .generate_btn_cancel {
        width: 100%;
    }

    .generate_btn_submit {
        width: 100%;
    }

    .generateCard_main {
        flex-direction: column;
    }

    .generateCard_numb_parent {
        justify-content: space-between;
        gap: 10px;
    }

    .generateCard_main {
        gap: 20px;
    }

    .generate_text {
        text-align: center;
        font-size: 20px;
    }

    .generate_main {
        padding: 18px 0;
    }
}

@media only screen and (max-width: 650px) {
    .generate_text {
        text-align: center;
        font-size: 25px;
    }
}

@media only screen and (max-width: 410px) {
    .generate_text {
        text-align: center;
        font-size: 16px;
    }
}
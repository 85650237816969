.targets{
    font-size: 30px;
    margin: 0;
    margin-top: 50px;
}

.target-review{
    color:#667085;
    font-size: 16px;
    margin-top: 10px;
}

.target-main-container{
padding-left: 20px;
padding-right: 20px;
background-color: #FFFFFF;
height:100%;
}
.custom-list {
    list-style: none; /* Remove default numbering */
    counter-reset: list-counter; /* Create a counter */
    padding-left: 0; /* Remove padding */
}

.custom-list li {
    position: relative; /* Position for pseudo element */
    margin: 20px 0 !important; /* Spacing between items */
    padding-left: 49px; /* Space for custom numbers */
    border-radius: 5px; /* Rounded corners */
}

.custom-list li::before {
    counter-increment: list-counter; /* Increment counter */
    content: counter(list-counter); /* Display counter */
    position: absolute; /* Position it */
    left: 0; /* Align left */
    bottom: -5px;
    background-color: var(--primary-color); /* Color for numbers */
    width: 36px; /* Width for numbers */
    height: 30px; /* Height for numbers */
    border-radius: 10px; /* Make it circular */
    display: flex; /* Center text */
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
}

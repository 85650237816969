body{
    background-color: white;
}
.header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid #d9d9d9;
}
  
.icon, .arrow {
    font-size: 24px;
    margin: 0 10px;
}
  
.btn {
    margin: 0 10px;
}
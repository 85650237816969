.tab_left{
    margin-left: 8px;
    padding: 10px 0;
}

.tabs_left{
    margin-left: 20px;
}
.tabs_le{
    margin-left: 6px;
}
.mainquaidah{
    width: 100%;
}

.moduleduration{
    padding: 15px;
    width: 255px;
    display: flex;
    border-radius: 8px;
    background-color: #EFF8FF;
}
.imgclock{
    padding: 1px;
    margin-right: 2px;
}

.collapsediv {
   border: 1px solid #FAFBFD;
   position: relative;
   top: 23px;
   border-radius: 1px;

}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 20px;
    color: var(--primary-color-local);
    background-color: #FAFBFD;
}
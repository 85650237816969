._notAccessContainer {
  width: 100%;
  border-radius: 20px;
  padding: 32px 32px 32px 32px;
  background-color: #fff;
  margin-top: 10px;
  min-height: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
}

._searchStudentModal ._body {
  width: 100%;
}

._searchStudentModal ._body > h2 {
  font-size: 18px;
  font-weight: 600;
  color: rgba(38, 37, 42, 1);
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: "Plus Jakarta Sans", sans-serif;
}
._searchStudentModal ._body > p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 112, 133, 1);
  margin: 0;
  margin-bottom: 20px;
  font-family: "Plus Jakarta Sans", sans-serif;
}

._searchStudentModal ._studentSelectBox {
  width: 100%;
  height: 42px;
}
._searchStudentModal ._studentSelectBox .ant-select-selector {
  border-radius: 8px;
  height: 42px;
}

._searchStudentModal ._footerButtons {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
._searchStudentModal ._footerButtons button {
  width: 100%;
  border-radius: 8px;
  font-size: 14px;
  height: 40px;
}
._searchStudentModal ._footerButtons button:nth-of-type(1) {
  background-color: black;
  color: #fff;
}
